@use '../base/variables.scss' as vars;

.landing {

  h1,
  h2.heading-1 {
    font-family: vars.$ff-primary;
    font-size: 50px;
    font-weight: 600;
    line-height: 1.22;
    color: #000;

    @include md {
      font-size: 40px;
    }
    @include sm {
      font-size: 30px;
    }
    @include xs {
      font-size: 24px;
    }
    @include xxs {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 40px;
    color: #fff;
    font-weight: 700;

    &.heading-1 {
      font-weight: 700;
    }

    @include md {
      font-size: 32px;
    }
    @include sm {
      font-size: 28px;
    }
    @include xs {
      font-size: 20px;
    }
    @include xxs {
      font-size: 16px;
    }
  }

  h3 {
    font-family: vars.$ff-primary;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.22;

    @include md {
      font-size: 24px;
    }
    @include sm {
      font-size: 18px;
    }
  }

  h4 {
    font-family: vars.$ff-primary;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.22;

    @include md {
      font-size: 20px;
    }
    @include sm {
      font-size: 18px;
    }
    @include xs {
      font-size: 16px;
    }
  }

  h5 {
    font-family: vars.$ff-primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.22;
  }
}

.custom-tooltip {
  font-size: 11px !important;
}