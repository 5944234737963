@use '../base/variables.scss' as vars;

.landing {

  .button-wrapper {
    margin: 0 0 29px;

    @include xs {
      margin: 0 0 19px;
    }
  }
  
  .button {
    font-family: vars.$ff-primary;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.22;
    background-color: #5EB258;
    color: #fff;
    height: 53px;
    padding: 0 30px;
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: darken(#5EB258, 10);
    }

    &--white {
      background-color: #fff;
      color: #5EB258;

      &:hover {
        color: #fff;
        background-color: lighten(#5EB258, 10);
      }
    }

    .mobile {
      display: none;
    }

    @include md {
      font-size: 16px;
      padding: 0 24px;
    }
    @include sm {
      font-size: 14px;
      height: 40px;
      padding: 0 20px;
    }
    @include xs {
      height: 30px;

      .mobile {
        display: inline;
      }

      .desktop {
        display: none;
      }
    }
  }

  .icon-button {
    width: 36px;
    height: 33px;
    background-color: #fff;
    border-radius: 6px;
    color: #5EB258;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      color: #fff;
      background-color: lighten(#5EB258, 10);
    }
    
    svg {
      display: block;
      font-size: 28px;
    }
  }
}
