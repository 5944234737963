@import './variables.scss';

.row {
  margin: 0;
}

.reset-password-button {
  cursor: pointer;
}

.breadcrumbs-wrap--stretch {
  display: flex;
  flex-direction: row;
}

.request-cta.request-cta--small.request-cta--center.selected {
  background-color: #2707df;
}

.request-cta.request-cta--small.request-cta--center.selected > .text {
  color: white;
}

.request-cta.request-cta--small.request-cta--center.disabled {
  background-color: lightgray !important;
  border-style: none;
  cursor: no-drop;
}

.request-cta.request-cta--small.request-cta--center.disabled > .text {
  color: gray !important;
}

.request-cta {
  max-width: 392px !important;
}

.request-cta.checkout {
  margin-top: 20px;
}

.col__text.col__text--green {
  cursor: pointer;
}

.row {
  align-items: flex-start;
}

.footer-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.request-submit-button {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #2707df;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
}

.request-submit-button.disabled {
  background-color: lightgray;
  color: gray;
  cursor: no-drop;
}

.checkout-form {
  max-width: 500px;
  width: 100%;
}

.chq-atc {
  display: inline-block;
  position: relative;
}

.chq-atc--button {
  position: relative !important;
  z-index: 1 !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: 32px !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  cursor: pointer !important;
  background: rgba(0,0,0,0) !important;
  box-sizing: border-box;
  color: #2707df !important;
  cursor: pointer;
  line-height: inherit;
  margin: 0;
  outline: none;
  font-size: 12px !important;
}

.calendar-button-start, .chq-atc--button {
  padding: 0 !important;
}

.chq-atc--button:focus {
  background-color: #eaeaea;
}

.chq-atc--button:hover {
  background-color: #eaeaea;
}

.chq-atc--button svg {
  vertical-align: text-bottom;
}

.chq-atc--button path {
  fill: #2707df !important;
}

.chq-atc--dropdown {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  z-index: 999 !important;
}

.chq-atc--dropdown a {
  color: #2707df !important;
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
}

.chq-atc--dropdown a:hover {
  background-color: #eaeaea;
}

.add-to-calendar-label {
  margin-left: 10px;
  text-align: left;
  font-size: 13px !important;
  line-height: 1.3;
}

.appointment-block-buttons-row {
  margin-top: 10px;
}

.menu-item-primary {
  color: #2707df !important;
}

.menu-item-secondary {
  color: red !important;
}

.tab__title {
  margin-bottom: 10px !important;
}

.meet-box.meet-box--margin {
  margin-top: 20px !important;
}

.textarea {
  resize: none;
}

.date-box {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.meet-desc {
  max-width: 75%;
}

.green-button {
  cursor: pointer;
}

.green-button.appointment-block {
  margin-top: 0 !important;
}

.green-button.full {
  background-color: #2707df;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  float:left;
  clear:both;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.green-button.full > .h6 {
  color: white !important;
  margin: auto;
}

.h6--gray {
  color: #9B9B9B;
  cursor: pointer;
}

.button-second {
  margin-left: 15px;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.appointment-block-att-button {
  margin-bottom: 20px;
}

.attachment-thumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px !important;
}

.attachments-grid {
  margin-top: 5px;
}

.attachment-thumb-label {
  /* margin-right: 10px; */
  font-size: 12px;
}
.attachment-thumb-label.link {
  cursor: pointer;
  color: #2707df;
}

.attachment-thumb-icon {
  margin-left: 10px;
}

.attachment-thumb-icon.delete {
  cursor: pointer;
}

.profile-save-button {
  margin-left: 0px !important;
  margin-top: 30px !important;
  padding: 12px !important;
 
}

.profile-form {
  width: 80%;
}

.tab__circles-wrap {
  cursor: pointer;
}

.accordion-wrapper {
  width: 100%;
}

.tab__circle-box {
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
  box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
}

.search-wrap {
  margin-bottom: 0 !important;
}

.w-slider-dot {
	width: 8px;
  height: 8px;
  background: #DEDEDE;
}
.w-slider-dot.w-active {
  background: #2707df;
}

.grid--step1 {
  margin-top: 0px !important;
}

.container.small {
  min-height: 0px !important;
}

.grid__desc {
  max-height: 280px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical; 
}

.grid--home .grid__desc {
  font-size: 14px;
  max-height: 110px;

  -webkit-line-clamp: 5;
}

@media (max-width: 767px) {
  .grid--home .btn--grid {
    padding-right: 40px;
  }

  .grid--home .btn--grid span{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
}


.breadcrumb-step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.img-text-wrap {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

@media screen and (max-width: 991px) {
  .img-text-wrap {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 479px) {
  .img-text-wrap {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
}

.shadow-box {
  opacity: 1;
}

.form__field-wrap.invalid > .form__text-field.w-input {
  border-color: red;
}

.form__field-wrap.invalid > .form__label {
  color: red;
}

.about-description {
  white-space: pre-wrap;
}

.about-logos-wrapper {
  display: flex;
  flex-direction: row;
}

.brand-logo {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  object-fit: contain;
}

.help-text {
  white-space: pre-wrap;
}

.input-with-adornment-wrapper {
  flex-direction: row;
  display: flex;
}

.input-adornment {
  margin-right: 10px;
  width: 20%;
}

.always-visible {
  opacity: 1 !important;
}

.sec {
  padding-top: 50px !important;
}

.grid {
  display: grid !important;
}

.nav__link.nav__link--dropdown.w-dropdown-toggle {
  color: #fff;
}

.nav__link.nav__link--dropdown.w-dropdown-toggle:hover {
  color: #2707df;
}

.apstep-profile-wrapper {
  width: 100%;
}

.cookie-banner-wrapper {
  position: fixed;
  bottom: 20vh;
  left: 32vw;
  right:32vw;
  padding: 50px 75px;
  background-color: white;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.cookie-banner-buttons-wrapper {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.cookie-banner-text {
  line-height: 1.3;
  font-weight: 500;
  color: white;
  display: block;
  padding: 9px 67px;
  text-align: left;
  width: 70%;
}

.cookie-banner-link {
  color: rgb(240, 240, 240);
  text-decoration: none;
  margin-left: 10px;
  font-weight: bold;
}

.cookie-banner-button {
  line-height: 32px;
  padding: 0px 8px;
  background: transparent;
  border: 1px solid white;
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  opacity: 1;
}


.cookie-banner-button-plain {
  line-height: 32px;
  padding: 0px 8px;
  background: transparent;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  color: white;
  cursor: pointer;
  opacity: 1;
  margin-left: 20px;
}

.field-hint {
  color: #9B9B9B;
  font-size: 12px;
}

.htva-label {
  font-size: 16px;
  font-weight: 400;
}

.list__img {
  width: 32px;
  height: 32px;
}

.h4-large {
  font-size: 22px;
}

.voucher-button-wrap {          
  margin-bottom: 13px;
  width: 100%;
}

.voucher-button-wrap input.voucher__text-field {
  margin-bottom: 10px !important;
}

.voucher_code {
  margin-right: 13px;
}

.voucher_code_button_text {
  font-size: 14px;
}

.header-strike-through {
  font-size: 36px;
  font-weight: 400;
  text-decoration: line-through;
}

.search-wrapper-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lottie-wrapper {
  overflow: hidden;
  margin-top: 30px;
}

.lottie-content {
  display: flex;
  margin: -10px;
}

.col.hero-col.title {
  margin-top: 4rem;
}

.textarea-wrap {
  flex-direction: column;
  margin-bottom: 20px;
}

.cookie_popup {
  margin: 16px;
  display: flex;
  flex-direction: column;
}
.cookie_text {
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
}
.cookie_buttons_choice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}
.cookie_buttons_more {
  display: flex;
  flex-direction: column;
}
.cookie_background {
  display: grid;
  place-items: center;
}

.link-box {
  width: 100%;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #E6ECF4;
  border-radius: 4px;
  -webkit-transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  color: #E6ECF4;
}
.link-box.link-box--blue {
  background-color: #E6ECF4;
  color: #354455;
  font-weight: 320;
}
.link-box.link-box--blue:hover {
  background-color: #e6ecf4a2;
}
.link-box.link-box--blue.disabled {
  background-color: #ccc;
  border-color: #ccc;
}
.cookie__h5{

  margin-top: 3em;
}

.cookie_button_left{
  margin-right: 2em;
}

.profile_save_button{
  width: 20%;
  border: 0 !important;
}

.checkout_button{
  margin-top: 20px;
}

.checkout_button_other_card-wrap {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.checkout_button_other_card{
  width: 60% !important;
}

a.dropdown__link.w-dropdown-link:hover{
  color: #21099a
}

.dropdown_calendar{
  padding: 0;
}

.dropdown_calendar button {
  margin: 0 16px;
}

.request-cta.request-cta--small.request-cta--center.w-inline-block:not(.selected):hover{
  background-color: $primary-color;
}

.request-cta.request-cta--small.request-cta--center.w-inline-block:not(.selected):hover div{
  color: white;
}

.svg_linkedin:hover path {
  fill: #0274B3;
}

.svg_facebook:hover path {
  fill: #4064AD;
}

.svg_youtube:hover path {
  fill: #FF0000;
}
.btn-rounded.w-button{
-webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}

.btn-rounded.w-button:hover {
  background-color: #4c2fee;
}

.text.text--small:hover {
  color:#21099a;
}

.price_offer {
  color: #9bb1c8;
  font-weight: 600;
}

.help-contact{
  display: flex;
  margin-top: 60px;
  align-items: center;
  flex-direction: row;
  font-size: 15px;
}

.mail-link{
  color: #3b62b5;
}

.mail-img {
  height: 50px;
  margin: 5px;
}

.expert-desc {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 10;
  display: block;
  width: 290px;
  /* height: max-content;
  max-height: -moz-max-content; */
  margin-top: 200px;
  /* padding: 20px 30px;
  border-radius: 6px; */
  padding: 0 !important;
  /* background-color: #fff;
  box-shadow: 0 15px 30px -10px rgba(56, 96, 165, 0.15); */
  background-color: transparent !important;
  box-shadow: 0 0 0 !important;
  opacity: 0;
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.expert-desc > div {
  background-color: #fff;
  box-shadow: 0 15px 30px -10px rgba(56, 96, 165, 0.15);
  padding: 20px 30px;
  border-radius: 6px;
}

.expert__p {
  margin-top: 15px;
  font-size: 14px;
  text-align: left;
}

.expert__h4 {
  font-size: 18px;
}

.form__characters {
  font-size: 10px;
  font-weight: 350;
  letter-spacing: 0.2px;
}

.voucher__text-field {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom: 0 !important;
}
.voucher__text-field:focus {
  border-color: #2707df;
}
.w-dropdown-list {
  display: flex;
}
.w-dropdown-list.w--open {
  display: flex;
}
.dropdown__list.account {
  display: block !important;
  padding: 20px !important;
  margin-top: 0px !important;
  position: relative !important;
}

.row.row--short2 {
  max-width: 1000px !important;
}

.title-wrap-skeleton {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title-wrap-skeleton-line {
  width: 60%;
  margin: 15px;
  height: 10px !important;
  border-radius: 5px !important;
  background-color: gainsboro !important;
}

.title-wrap-skeleton-line > .MuiLinearProgress-bar {
  background-color: silver !important;
}

.title-wrap-skeleton-line.first {
  width: 80%;
}

.spinner-white {
  color: white !important;
}

.title-wrap-skeleton-line.col-1 > .MuiLinearProgress-bar1Indeterminate {
  animation-delay: 600ms !important;
}
.title-wrap-skeleton-line.col-2 > .MuiLinearProgress-bar1Indeterminate {
  animation-delay: 1200ms !important;
}
.title-wrap-skeleton-line.col-3 > .MuiLinearProgress-bar1Indeterminate {
  animation-delay: 1800ms !important;
}
.title-wrap-skeleton-line.col-1 > .MuiLinearProgress-bar2Indeterminate {
  animation-delay: 600ms !important;
}
.title-wrap-skeleton-line.col-2 > .MuiLinearProgress-bar2Indeterminate {
  animation-delay: 1200ms !important;
}
.title-wrap-skeleton-line.col-3 > .MuiLinearProgress-bar2Indeterminate {
  animation-delay: 1800ms !important;
}
.grid__title-box.skeleton {
  width: 100%;
  margin-top: -30px;
}
@media (max-width: 991px) {
  .grid__title-box.skeleton {
    margin-top: 0;
  }
}
.skeleton-background-box {
  background-color: whitesmoke;
  width: 100%;
  height: 100%;
}

.axa__img {
  width: 60%;
}

.legalcopy {
  margin-top: 10px;
  padding-top: 0px;
  color: #b7b7b7;
  font-size: 11px;
  line-height: 1.2em;
  padding-bottom: 20px;
}

/* XMAS BANNER */
.lv-bnr {
  max-width: 680px;
  margin: 0 auto;
} 

.lv-bnr--2 {
  margin-top: 40px;
}

/* Button */
.button-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
} 

.button-wrapper--center {
  display: flex;
  justify-content: center;
}

.button-wrapper .button {
  height: 56px;
  border-radius: 27px;
  padding: 0 18px 0 33px;
  display: inline-flex;
  align-items: center;
  background-color: $primary-color;
  color: #fff;
  font-weight: 600;
  transition: background-color .3s ease;
  cursor: pointer;
}

.button-wrapper .button:hover {
  background-color: #4c2fee;
}

.button-wrapper .button svg {
  display: block;
  margin-left: 7px;
}

/* Cookies section */
.cookies-section-wrapper {
  background-color: #F7F6F6;
  box-shadow: 0 1px 10px 0 rgba(129, 129, 129, 0.36);
  padding: 22px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 500px;
  overflow-y: auto;
}

.cookies-section-wrapper .container {
  display: block;
}

.cookies-section {
  position: relative;
  padding-left: 147px;
}

.cookies-section__cookie {
  position: absolute;
  top: 24px;
  left: 0;
  max-width: 100%;
  display: block;
}

.cookies-section__top {
  margin-bottom: 17px;
}

.cookies-section__top p {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0;
  color: #020F1D;
}

.cookies-section__actions {
  margin-bottom: 5px;
}

.cookies-section__actions a,
.configure-body--cookies-section a {
  display: inline-flex;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: 27px;
  padding: 6px 16px;
  color: #2707df;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 15px;
  transition: background-color .3s ease;
}

.cookies-section__actions a.green,
.configure-body--cookies-section a.green {
  background-color: #2707df;
  color: #fff;
}

.cookies-section__actions a:hover {
  background-color: #f1f1f1;
}

.cookies-section__actions a.green:hover,
.configure-body--cookies-section a.green:hover {
  background-color: #4c2fee;
}

.cookies-section__bottom p {
  font-size: 13px;
  color: #020F1D;
  letter-spacing: 0;
  text-align: left;
}

.cookies-section__bottom p a {
  color: #2707df;
  font-weight: 500;
}

.cookies-section__bottom p a:hover {
  text-decoration: none;
}

.configure-body--cookies-section {
  padding: 40px 40px 20px;
}

.configure-body--cookies-section .configure {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 15px;
}

.configure-body--cookies-section a.green {
  width: 100%;
  justify-content: center;
}

@media (max-width: 767px) {
  .cookies-section {
    padding-left: 50px;
  }
  
  .cookies-section__cookie {
    max-width: 40px;
    top: 10px;
  }
}

/* Make appointment */
.container--make-appointment p{
  color: #030F1C;
  font-size: 15px;
}

.container--make-appointment .button-wrapper {
  margin-bottom: 22px;
  margin-top: 38px;
}

.container--make-appointment p.direct {
  margin-bottom: 33px;
}

.or-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.or-wrapper .line {
  width: 57px;
  height: 1px;
  background-color: #9BB1C8;
}

.or-wrapper p {
  margin: 0 25px;
}

.container--make-appointment .search-wrap {
  margin-top: 33px;
}

.container--with-breadcrumbs {
  margin-top: -50px;
}

.expert-info-wrap {
  margin: 0;
  margin: 0px 40px 20px 40px;
}

.circles-wrap {
  margin-bottom: 0;
}

.circles-wrap.signup {
  width: auto;
  height: auto;
  margin-top: 0;
  /* margin-bottom: 30px; */
}
.col__cont-wrap.col__cont-wrap--relative {
  flex-wrap: wrap;
  row-gap: 4vw;
  column-gap: 4vw;
  height: auto;
}

/* Breadcrumbs */
.breadcrumbs-wrap {
  position: sticky;
  top: 122px;
  z-index: 998;
} 

@media (max-width: 767px) {
  .breadcrumbs-wrap {
    top: 126px;
  }
}

/************************ BECI ***************************/

.header--beci,.footer--beci {

  h1,
  .sec__h2,
  .auth-dialog h1,
  .pop-up h1,
  .dialog h2,
  .dialog p,
  .dialog button {
    font-family: 'Rubik', sans-serif;
  }

  .nav-link,
  .nav-link.w--current {
    color: var(--color-beci-blue);
  }
  .nav-link:hover {
    color: var(--color-beci-blue);
  }
  .nav-line {
    background-color: var(--color-beci-blue);
  }

  .under-footer {
    background-color: var(--color-beci-blue);
  }
  .footer {
    background-color: var(--color-beci-blue);
  }

  .subscription__product__item__name {
    color: var(--color-beci-blue);
  }

  .sec__title {
    color: var(--color-beci-blue);
  }

  .cta-icon-wrap {
    border-color: var(--color-beci-blue);
  }

  .submitButton {
    background: var(--color-beci-blue) !important;
  }

  .nav-btn {
    background-color: var(--color-beci-blue);
    border-color: var(--color-beci-blue);
  }

  .nav-btn:hover {
    border-color: var(--color-beci-blue) !important;
  }

  .nav-btn h4 {
    color: #fff !important;
  }

  .growing-div {
    background-color: var(--color-beci-blue-dark);
  }

  .nav-link--lang {
    color: var(--color-beci-blue);
  }

  .thin-line {
    background-color: var(--color-beci-blue);
  }

  .form__btn,
  .c-form__btn,
  .d-btn,
  .q-btn.btn--red {
    background-color: var(--color-beci-blue-light) !important;
  }

  .form__btn:hover,
  .c-form__btn:hover,
  .d-btn:hover,
  .q-btn.btn--red .growing-div-2 {
    background-color: var(--color-beci-blue-dark) !important;
  }

  .f__btn-line-wrap .red-line,
  .c-btn-line-wrap .red-line,
  .d__btn-line-wrap .red-line {
    background-color: var(--color-beci-blue-dark);
  }

  .checkbox-2.w--redirected-checked {
    background-color: var(--color-beci-blue-light);
    border-color: var(--color-beci-blue-dark);
  }

  .c-btn.btn--red {
    border-color: var(--color-beci-blue-light);
  }

  .c-btn.btn--red h4.nav__h,
  .tab__title:not(.tab__title--black) {
    color: var(--color-beci-blue);
  }

  .c-btn.btn--red:hover {
    border-color: var(--color-beci-blue-dark);
  }

  .btn,
  .auth-dialog .btn {
    background-color: var(--color-beci-blue-light);
    border-bottom-color: var(--color-beci-blue-dark);
  }

  .btn .btn__h,
  .auth-dialog .btn .nav__h {
    color: #fff !important;
  }

  .btn:hover,
  .auth-dialog .btn:hover {
    background-color: var(--color-beci-blue-dark);
  }

  .MuiCircularProgress-colorSecondary {
    color: var(--color-beci-blue);
  }

  .title-wrap,
  .cont__h4,
  .d-cont__h4,
  .hero-link,
  .hero-cont:hover,
  .q-btn1,
  .auth-dialog .reset-password-btn:hover,
  .pop-up .pop-up__btn {
    color: var(--color-beci-blue);
  }

  .q-btn1 {
    border-color: var(--color-beci-blue);
  }

  .q-btn1:hover {
    border-color: var(--color-beci-blue-dark);
    background-color: var(--color-beci-blue);
    color: #fff;
  }

  .pop-up .btn-line-wrap .red-line {
    background-color: var(--color-beci-blue);
  }

  .c-btn.remove {
    border-color: var(--color-simplyMove-orange);
    color: var(--color-simplyMove-orange);
    margin-top: 20px;
  }

  .c-btn.remove:hover {
    background-color: var(--color-simplyMove-orange);
    color: #fff;
  }

  .dialog .form-error {
    color: var(--color-simplyMove-orange);
  }

  .sticky-collapse {
    top: 122px;
  }

  h1,
  h2,
  .hero__h,
  .sec__h2 {
    color: #2800d7;
    font-family: 'Nunito', sans-serif;
  }

  .hero__h span {
    color: #2800d7 !important;
  }

  .hero__h {
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    margin-bottom: 36px;
    max-width: 986px;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitle-2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    max-width: 921px;
    margin: 0 auto 100px;
  }

  .beci-home-steps {

    .col {

      .faciles-cont {

        div {
          width: 63px;
          height: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 24px;
        }

        img {
          width: auto;
          height: auto;
          max-width: 63px;
          max-height: 66px;
          display: block;
          margin-bottom: 0;
        }
      }
    }
  }

  .c-btn.btn--red {
    color: #2800d7;
    border-color: #2800d7;

    &:hover {
      background-color: #2800d7;
      color: #fff;
      
      h4.nav__h {
        color: #fff !important;
      }
    }
  }

  .w-nav-brand {

    @media (max-width: 480px) {
      padding-left: 0;
    }
  }

  .nav-link-wrap--lang {

    .nav-btn {
      border-width: 1px;

      &:hover {

        .login-profile-icon {
          color: #212529;
        }
      }
    }

    .login-profile-icon {
      display: none;
    }

    @media (max-width: 991px) {
      margin-right: 0;
      margin-left: 15px;

      .nav-btn {
        display: flex;
        margin-top: 0;
      }
    }

    @media (max-width: 480px) {
      .nav-btn {
        width: 56px;
        margin-left: 0px;

        h4:not(.support__h) {
          display: none;
        }

        .login-profile-icon {
          display: flex;
          font-size: 24px;
        }
      }
    }
  }

  .nav-link-login-menu-btn-wrap {
    display: flex;
    align-items: center;
  }

  .auth-dialog {

    .btn {

      .nav__h {
        line-height: 1.3;
        margin: 0;
        padding: 3px 0;
      }
    }
  }

  .tabs__cont-wrap {

    .link {

      svg {
        color: #2800d7;
      }
    }
  }

  .tabs-menu {

    .tab-link__line.highlighted {
      background-color: #2800d7;
    }
  }

  .tabs {

    &__link {

      &:hover {
        background-color: #2800d7;
        color: #fff;
      }

      &:hover,
      &.highlighted {
        background-color: #fff;

        &::after {
          content: '';
          height: 3px;
          width: 100%;
          position: absolute;
          bottom: -1px;
          left: 0;
          background-color: #2800d7;
        }

        // &::before {
        //   content: '';
        //   height: 30px;
        //   width: 1px;
        //   position: absolute;
        //   left: -1px;
        //   top: auto;
        //   right: auto;
        //   bottom: auto;
        //   background-color: #fff;
        // }

        // .tabs__g-line {
        //   display: none;
        // }
      }
    }
  }

  .sticky-collapse {
    z-index: 1001;
  }

  .header-bottom {

    .nav__g-line {
      display: none;
    }
  }

  .button {

    &--primary {
      background-color: #2800d7;

      &:hover {
        background-color: darken(#2800d7, 10);
      }
    }
  }

  .d-title-link-2 {

    .title-wrap-2 {
      opacity: 1;
    }
    
    .title-wrap-2 .cont__h4-2 {
      color: #2800d7;
    }
    
    &:visited {

      .title-wrap-2 .cont__h4-2 { 
        color: #A18EF5;
      }
    }
    
    &:hover {

      .title-wrap-2 .cont__h4-2 {
        color: #1A008C;
      }
    }

  }

  .f-form__label {
    margin-bottom: 2px;
  }

  .doc-time-wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid rgba(40,1,216,0.13);

    @media (max-width: 600px) {
      display: inline-block;
    }

    .d-hero__title {
      display: flex;
      align-items: center;
      padding: 10px 24px 10px 10px;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #1B2421;

      @media (max-width: 600px) {
        width: 100%;
      }

      &:first-child {
        border-right: 1px solid rgba(40,1,216,0.13);

        @media (max-width: 600px) {
          border-bottom: 1px solid rgba(40,1,216,0.13);
          border-right: 0;
        }
      }

      &:last-child {
        padding-left: 19px;

        @media (max-width: 600px) {
          padding-left: 10px;
        }
      }

      svg {
        display: block;
        margin-right: 9px;
        color: #2800d7;
      }

      .d-hero__span {
        margin-left: 4px;
      }
    }
  }

  .menu-btn {

    img {
      max-width: 32px;
    }
  }

  .document-form-v2 {
    padding-top: 0;
  }

  .MuiLinearProgress-root {
    background-color: rgba(40, 1, 216, 0.2);
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #2800d7;
  }

  .profile-select-field {
    width: 430px;
    height: 40px;
    padding: 8px 12px;

    @media (max-width: 991px) {
      width: 85%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
    
    &::before {
      border-color: rgba(51, 51, 51, 0.1);
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }

  .download-title-wrap {

    .download-title {
      color: #2800d7;
    }

    .download-icon {
      fill: #2800d7;
    }

    h4 {
      margin-top: 0;
    }

    .w-inline-block {
      display: inline-flex;
      align-items: center;
    }

    .close-icn {

      .svg-icon {
        font-size: 32px;
      }
    }

    .svg-icon {
      color: #2800d7;
      font-size: 24px;
      margin-right: 7px;
      display: block;
    }
  }

  .checkbox-3.w--redirected-checked {
    background-color: #2800d7;
  }

  .auth-doc-link {
    color: #2800d7;
  }

  .c-btn-line-wrap {

    @media (max-width: 991px) {
      .c-btn.remove {
        display: flex;
      }
    }
  }

  .loading-popup {
    width: 90%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #333;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .w-tab-content--profile {

    .w-tab-pane {
      padding-bottom: 0;
    }

    .change-password-link {
      color: var(--color-beci-blue-light);
      text-decoration: none;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
      }
    }

    .delete-profile-btn {
      color: var(--color-simplyMove-orange);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .profile-save-btn {
      margin-top: 20px;
    }
  }

  .cookies-modal {
    
    .switch-el {
    
      label {
    
        input:checked ~ .switch-el__btn {
          background-color: #2800d7;
          border-color: #2800d7;
        }
      }
    }

    .button {

      &--primary {
        background-color: #2800d7;

        &:hover {
          background-color: darken(#2800d7, 10);
        }
      }
    }
  }
}

.categories-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 35px;
  max-width: 90%;
  flex-wrap: wrap;
  padding-top: 35px;
}

.category-img-text-wrap {
  width: 330px;
  height: 300px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 24px;
  -webkit-transition: box-shadow 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  &:hover {
    .category-text-box {
      transform: translateY(0%);
    }
    .category-text-bg {
      opacity: 1;
    }
    .category-h3 {
      span {
        white-space: unset;
      }
    }
  }
}

.category-grid__desc {
  font-size: 14px;
  text-align: left;
  color: white;
  margin-top: 0;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // -webkit-line-clamp: 4;
  -webkit-line-clamp: 5;
}

.category-h3 {
  font-size: 22px;
  padding-bottom: 15px;
  color: #fff;
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 97%;
    white-space: nowrap;
  }
}

.category-desc-falloff {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 125px;
  // background-color: rgb(24, 9, 84);
  background: linear-gradient(0deg, rgba(24, 9, 84, 1), rgba(24, 9, 84, 0.85), rgba(24, 9, 84, 0.7), rgba(24, 9, 84, 0));
  z-index: 1;
}

.category-img-text-wrap:active {
  box-shadow: 0 0 0 4px hsla(0, 0%, 62.4%, 0.4);
}

.category-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  background-color: #000;
  opacity: 0.25;
}

.category-img-box {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.category-text-box {
  width: 330px;
  height: 300px;
  transition: all 200ms ease-in-out;
  transform: translateY(74%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: 47pz;
}

.category-text-box {
  padding: 30px 32px 30px;
}

.category-grid__title-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: stretch;
  z-index: 1;
}

.category-text-bg {
  position: absolute;
  transition: all 200ms ease-in-out;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(24, 9, 84);
}

.category-btn-wrap {
  position: absolute;
  bottom: 30px;
  display: flex;
  // min-height: 47px;
  height: 47px;
  left: 30px;
  right: 30px;
  width: auto;
}

.category-detail-description {
  text-align: left;
  font-size: 1rem;
}

.appointment-edit-form {
  margin: 10px 0 0 0;
}

.btn.btn--grid.w-button {
  justify-content: space-between;
  span {
    font-weight: normal;
  }
}