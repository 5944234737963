.expert-card-skeleton {
  width: 290px;
  height: 427px;
  background-color: #fff;
  border-radius: 10px;
  padding: 28px 34px;
  border: 1px solid rgba(56,96,165,0.14);
  box-shadow: 0 4px 8px 0 rgba(56,96,165,0.15);
  margin: 10px;
  display: flex;
  flex-direction: column;

  &__head {
    width: 120px;
    height: 120px;
    background-color: #eee;
    border-radius: 50%;
    margin: 0 auto 24px;
  }

  &__line {
    height: 10px !important;
    width: 80%;
    background-color: gainsboro !important;
    border-radius: 5px;
    margin-bottom: 15px;
    align-self: center;

    .MuiLinearProgress-bar {
      background-color: silver;
    }

    &.full {
      width: 100%;
    }

    &.small {
      width: 50px;
      margin-top: 30px;
    }

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;
      width: 148px;
      height: 45px !important;
      border-radius: 80px;
    }
  }
}