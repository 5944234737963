.landing {

  .video-section {
    padding: 127px 15px 130px;
    max-width: 1196px;
    margin: 0 auto;
    display: flex;

    @include md {
      padding: 80px 15px;
    }
    @include xs {
      display: none;
    }

    &__left {
      padding-top: 25px;

      h3 {
        margin-bottom: 50px;
      }

      .text {

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.357;
          opacity: 0.6;

          span {
            display: block;
          }
        }
      }
    }

    &__right {
      margin-left: 45px;
      min-width: 44.23%;
      padding-top: 24.74%;
      position: relative;

      iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}