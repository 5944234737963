.landing {

  .testimonials {
    padding: 54px 47px 122px;
    background-color: #F7F7F7;

    @media (min-width: 1440px) {
      width: calc(100vw - 17px);
      margin-left: -108px;
    }
    
    &__inner {
      max-width: 1440px;
      margin: 0 auto;
    }

    @include xxs {
      padding: 33px 35px 36px;
    }

    &__head {
      max-width: 668px;
      margin: 0 auto 30px;

      h3 {
        text-align: center;

        @include xxs {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }

    &__slider {
      max-width: 1196px;
      margin: 0 auto;
      position: relative;

      &_navigation-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -75px;
      }
      
      &_navigation-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -75px;
      }

      @media (max-width: 1400px) {
        &_navigation-prev {
          left: -42px;
        }
        &_navigation-next {
          right: -42px;
        }
      }

      @include xxs {
        .icon-button {
          background-color: transparent;
          box-shadow: none;
          width: 20px;
          height: 20px;
        }
        &_navigation-prev {
          left: -30px;
        }
        &_navigation-next {
          right: -30px;
        }
      }

      .swiper-wrapper {
        align-items: stretch !important;
      }

      .swiper-slide {
        height: auto !important;
      }
    }

    &__slide {
      background-color: #fff;
      border-radius: 20px;
      padding: 46px 52px;
      height: 100%;

      @include xxs {
        padding: 19px 26px 23px;
      }

      .author {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.22;
        margin-bottom: 14px;
        text-align: left;

        @include xxs {
          font-size: 15px;
        }
      }

      .text {
        margin-bottom: 25px;

        p {
          font-size: 14px;
          line-height: 1.357;
          opacity: 0.6;
          text-align: left;
        }

        @include xxs {
          margin-bottom: 14px;
        
          p {
            font-size: 12px;
          }
        }
      }

      .rating {
        display: flex;
        align-items: center;

        svg {
          display: block;
          color: #5EB258;
        }
      }
    }
  }
}