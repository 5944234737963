.landing {

  .features {
    max-width: 1069px;
    padding: 75px 15px 142px;
    margin: 0 auto;

    @include xs{
      padding: 36px 29px 48px;
    }
  
    &__head {
      margin-bottom: 76px;
      text-align: center;

      @include sm {
        margin-bottom: 35px;
      }
    }
  
    &__boxes {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 53px;

      @include xs {
        display: block;
      }
    }
  
    &__box {
      width: 30.7%;
      text-align: center;
      margin-bottom: 30px;
  
      &_lottie {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        overflow: hidden;

        > div {
          position: relative;
          top: -2px;
          left: -2px;
          width: 154px;
          height: 154px;
        }
      }
  
      h5 {
        color: #000;
        margin-bottom: 24px;
      }
  
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.357;
        opacity: 0.6;
      }

      @include xs {
        width: 100%;
        position: relative;
        padding-right: 90px;
        margin-bottom: 36px;

        &_lottie {
          position: absolute;
          bottom: 0;
          right: -5px;
          width: 90px;
          height: 90px;

          > div {
            width: 94px;
            height: 94px;

            > div {
              width: 94px !important;
              height: 94px !important;
            }
          }
        }

        &:nth-child(2n+2) {
          padding-right: 0;
          padding-left: 90px;

          .features__box_lottie {
            left: -5px;
            right: auto;
          }

          h5 {
            text-align: right;
          }
          p {
            text-align: right;
          }
        }

        h5 {
          margin-bottom: 12px;
          text-align: left;
        }

        p {
          text-align: left;
          font-size: 12px;
        }
      }
    }
  
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .button {
  
        &:first-child {
          margin-right: 53px;
        }

        &:last-child {
          margin-right: 0;
        }

        @include xs {
          &:first-child {
            margin-right: 0;
          }

          &--white {
            display: none;
          }
        }
      }

      .to-faq-btn-wrapper {
        margin-top: 0 !important;
      }
    }

    &--2 {
      padding-top: 0;

      @include xs {

        .features__boxes {
          margin-bottom: 30px;
        }

        .features__box {
          padding-left: 90px;
          padding-right: 0;
          min-height: 90px;
          display: flex;
          align-items: center;
          margin-bottom: 0;

          &_lottie {
            left: -5px;
            right: auto;
            top: 50%;
            transform: translateY(-50%);
          }

          &:nth-child(1) {
            h5::before {
              content: '1.';
            }
          }
          &:nth-child(2) {
            h5::before {
              content: '2.';
            }  
          }
          &:nth-child(3) {
            h5::before {
              content: '3.';
            }
          }

          h5 {
            text-align: left;
          }

          p {
            display: none;
          }
        }
      }
    }

    .to-faq-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
  }
}