.sticky-collapse {
  top: 122px;
}

.header-top,
.header-bottom {

  @media (max-width: 991px) {
    .container {
      padding: 0 15px;
    }
  }

  @media (max-width: 480px) {
    .container {
      padding: 0 5px;
    }
  }
}

.header-top {
  padding: 4px 0;
  border-bottom: 1px solid #dee2e6;
  background-color: #2800d7;

  &_inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .languages {
    display: flex;
    align-items: center;

    .nav-link--lang {
      color: #fff;
      font-size: 1rem;
      line-height: 1.5;
      padding: 0 1rem;
      height: 40px;
      margin: 0;
      display: flex;
      align-items: center;
      opacity: 1;

      &:hover,
      &.highlighted {
        background-color: #1f00a4;
        color: #fff;
      }
    }
  }

  .social,
  .contacts {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .social {

    a {
      padding: 0 0.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      height: 40px;

      svg {
        font-size: 1.25rem;
        display: block;
      }

      &:hover {
        background-color: #1f00a4;
      }
    }
  }

  .contacts {

    a {
      padding: 0 0.75rem;
      height: 40px;
      font-size: 1rem;
      line-height: 1.5;
      color: #fff;
      text-decoration: none;
      display: flex;
      align-items: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      svg {
        font-size: 1.25rem;
        margin-right: 2px;
      }

      &:hover {
        background-color: #1f00a4;
      }

      @media (max-width: 991px) {
        span {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }
    }
  }
}

.header-bottom {
  position: sticky;
  top: 0;
  z-index: 1001;
  background-color: #2800d7;
  height: 122px;

  &.no-sticky {
    position: relative;
  }

  .w-nav {
    width: 100%;
    background-color: #2800d7;
    // position: sticky;

    .nav-menu {
      margin-bottom: 0;
      background-color: #2800d7;

      @media (max-width: 991px) {
        padding: 10px 20px;
      }
    }

    .nav-link-wrap {
      padding-left: 0;
      padding-right: 0;

      .nav-link {
        color: #fff;
        height: 122px;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        margin: 0;

        @media (max-width: 991px) {
          height: 40px;
          width: 100%;
        }

        &:hover {
          background-color: #1f00a4;
        }

      }

      .nav-line {
        display: none !important;
      }
    }

    .brand-link {
      height: 122px;
    }

    .navbar-logo {
      height: 90px;
    }

    .nav-btn {
      color: #f8f9fa;
      border-color: #f8f9fa;
      background-color: transparent;

      &:hover {
        background-color: #f8f9fa;
        border-color: #f8f9fa !important;
        
        h4 {
          color: #212529 !important;
        }
      }

      .growing-div {
        display: none;
      }
    }

    @media (max-width: 991px) {
      .w-nav-button {
        position: relative !important;
        width: 56px;
        height: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 1px solid rgba(255,255,255,0.1);
      }

      .nav__g-line {
        display: none;
      }
    }
  }
}

.flip-card {
  background-color: transparent;
  width: 110px;
  height: 110px;
  perspective: 1000px; 
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-back {
  background-color: #2800d7;
  color: white;
  transform: rotateY(180deg);

  p {
    font-size: 0.875rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/*! CSS Used from: http://localhost:3000/styles/normalize.css */
a{background-color:transparent;}
a:active,a:hover{outline:0;}
svg:not(:root){overflow:hidden;}
/*! CSS Used from: http://localhost:3000/styles/webflow.css */
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
ul{margin-top:0px;margin-bottom:10px;padding-left:40px;}
.w-nav-link{position:relative;display:inline-block;vertical-align:top;text-decoration:none;color:#222222;padding:20px;text-align:left;margin-left:auto;margin-right:auto;}
/*! CSS Used from: http://localhost:3000/styles/alink-5d858e.webflow.css */
a{text-decoration:underline;}
.container{width:100%;max-width:1392px;margin-right:auto;margin-left:auto;padding-right:24px;padding-left:24px;}
.nav-link--lang{margin-right:8px;margin-left:8px;padding:24px 0px;opacity:0.5;color:salmon;font-size:13px;font-weight:600;letter-spacing:1px;text-transform:uppercase;cursor:pointer;}
.nav-link--lang:hover{opacity:1;color:salmon;}
@media screen and (max-width: 991px){
.container{padding-right:0px;padding-left:0px;}
.nav-link--lang{-webkit-transition:opacity 300ms ease-in-out;transition:opacity 300ms ease-in-out;text-align:center;}
}
/*! CSS Used from: http://localhost:3000/styles/App.css */
.nav-link--lang.highlighted{opacity:1;}
.sec-wrap--beci .nav-link--lang{color:var(--color-beci-blue);}
/*! CSS Used from: Embedded */
@media (max-width: 991px){
.header-top .container{padding:0 15px;}
}
@media (max-width: 480px){
.header-top .container{padding:0 5px;}
}
.header-top{padding:4px 0;border-bottom:1px solid #dee2e6;background-color:#2800d7;}
.header-top_inner{width:100%;display:flex;justify-content:flex-end;align-items:center;}
.header-top .languages{display:flex;align-items:center;}
.header-top .languages .nav-link--lang{color:#fff;font-size:1rem;line-height:1.5;padding:0 1rem;height:40px;margin:0;display:flex;align-items:center;opacity:1;}
.header-top .languages .nav-link--lang:hover,.header-top .languages .nav-link--lang.highlighted{background-color:#1f00a4;color:#fff;}
.header-top .social,.header-top .contacts{display:flex;align-items:center;list-style:none;padding-left:0;margin-bottom:0;}
.header-top .social a{padding:0 0.5rem;color:#fff;display:flex;align-items:center;height:40px;}
.header-top .social a svg{font-size:1.25rem;display:block;}
.header-top .social a:hover{background-color:#1f00a4;}
.header-top .contacts a{padding:0 0.75rem;height:40px;font-size:1rem;line-height:1.5;color:#fff;text-decoration:none;display:flex;align-items:center;transition:color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;}
.header-top .contacts a svg{font-size:1.25rem;margin-right:2px;}
.header-top .contacts a:hover{background-color:#1f00a4;}
@media (max-width: 991px){
.header-top .contacts a span{display:none;}
.header-top .contacts a svg{margin-right:0;}
}
/*! CSS Used from: Embedded */
.MuiSvgIcon-root{fill:currentColor;width:1em;height:1em;display:inline-block;font-size:1.5rem;transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;flex-shrink:0;user-select:none;}

/*! CSS Used from: http://localhost:3000/styles/normalize.css */
nav{display:block;}
img{border:0;}
svg:not(:root){overflow:hidden;}
/*! CSS Used from: http://localhost:3000/styles/webflow.css */
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
img{max-width:100%;vertical-align:middle;display:inline-block;}
.w-inline-block{max-width:100%;display:inline-block;}
h4{font-weight:bold;margin-bottom:10px;}
h4{font-size:18px;line-height:24px;margin-top:10px;}
p{margin-top:0;margin-bottom:10px;}
@media screen and (max-width: 767px){
.w-nav-brand{padding-left:10px;}
}
.w-nav{position:relative;background:#dddddd;z-index:1000;}
.w-nav:before,.w-nav:after{content:" ";display:table;grid-column-start:1;grid-row-start:1;grid-column-end:2;grid-row-end:2;}
.w-nav:after{clear:both;}
.w-nav-brand{position:relative;float:left;text-decoration:none;color:#333333;}
.w-nav-link{position:relative;display:inline-block;vertical-align:top;text-decoration:none;color:#222222;padding:20px;text-align:left;margin-left:auto;margin-right:auto;}
.w-nav-link.w--current{color:#0082f3;}
.w-nav-menu{position:relative;float:right;}
.w-nav-overlay{position:absolute;overflow:hidden;display:none;top:100%;left:0;right:0;width:100%;}
.w-nav-button{position:relative;float:right;padding:18px;font-size:24px;display:none;cursor:pointer;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);tap-highlight-color:rgba(0, 0, 0, 0);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
@media screen and (max-width: 991px){
.w-nav[data-collapse="medium"] .w-nav-menu{display:none;}
.w-nav[data-collapse="medium"] .w-nav-button{display:block;}
}
@media screen and (max-width: 767px){
.w-nav-brand{padding-left:10px;}
}
/*! CSS Used from: http://localhost:3000/styles/alink-5d858e.webflow.css */
h4{margin-top:10px;margin-bottom:10px;font-size:18px;line-height:24px;font-weight:600;}
p{margin-bottom:0px;}
.navbar{position:-webkit-sticky;position:sticky;top:0px;z-index:5;border-bottom:1px none #d7dfe5;background-color:#fff;}
.nav-link{margin-right:8px;margin-left:8px;padding:24px 0px;color:#00008f;font-size:13px;font-weight:600;letter-spacing:1px;text-transform:uppercase;cursor:pointer;}
.nav-link:hover{color:#00005b;}
.nav-link.w--current{color:#f07662;}
.nav-link-wrap{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-right:22px;padding-left:22px;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;}
.nav__h{position:relative;z-index:3;color:#f07662;font-size:13px;line-height:1.3px;font-weight:600;letter-spacing:1px;text-decoration:none;text-transform:uppercase;}
.nav__h:hover{color:#ec4d33;}
.nav-line{position:absolute;left:0%;top:auto;right:0%;bottom:0%;width:100%;height:5px;background-color:#f07662;opacity:0;-webkit-transform-origin:0% 50%;-ms-transform-origin:0% 50%;transform-origin:0% 50%;}
.nav-btn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:8px 22px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;border-style:solid;border-width:3px;border-color:#f07662;text-decoration:none;cursor:pointer;}
.nav-btn.btn.btn--red{height:40px;margin-left:8px;border-width:2px;}
.nav-btn.btn.btn--red:hover{border-color:#ec4d33;}
.nav-menu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:-4px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}
.container{width:100%;max-width:1392px;margin-right:auto;margin-left:auto;padding-right:24px;padding-left:24px;}
.brand-link{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100px;height:70px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-webkit-align-items:flex-start;-ms-flex-align:start;align-items:flex-start;-webkit-align-self:flex-start;-ms-flex-item-align:start;align-self:flex-start;border:1px none #000;}
.nav-wrap{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:0px;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}
.btn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:8px 22px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;border-style:solid;border-width:2px;border-color:#00008f;font-weight:600;letter-spacing:1.25px;text-decoration:none;cursor:pointer;}
.btn.btn--red{position:relative;overflow:hidden;max-width:180px;border-color:salmon;background-color:transparent;-webkit-transform-origin:50% 50%;-ms-transform-origin:50% 50%;transform-origin:50% 50%;}
.btn.btn--red:hover{border-color:#ec4d33;}
.nav__g-line{height:1px;background-color:#d7dfe5;}
.growing-div{position:absolute;left:auto;top:auto;right:auto;bottom:auto;width:0%;height:500%;background-color:#ec4d33;opacity:0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);}
.nav-link-wrap--lang{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;cursor:pointer;}
@media screen and (max-width: 991px){
.nav-link{width:200px;text-align:center;}
.nav-link-wrap{-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}
.menu-btn{position:absolute;left:auto;top:0%;right:0%;bottom:0%;}
.nav-btn{display:none;}
.nav-btn.btn.btn--red{display:none;}
.nav-menu{background-color:#fff;}
.container{padding-right:0px;padding-left:0px;}
.close-icon{position:absolute;left:0%;top:0%;right:0%;bottom:0%;z-index:1;margin:auto;opacity:0;}
.nav-wrap{position:relative;}
.btn{display:none;}
.btn.btn--red{display:block;margin-top:20px;}
.nav-link-wrap--lang{margin-right:60px;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}
}
@media screen and (max-width: 767px){
.nav-link-wrap--lang{margin-right:60px;}
}
@media screen and (max-width: 479px){
.btn{display:block;margin-top:24px;}
.nav-link-wrap--lang{margin-right:60px;}
}
/*! CSS Used from: http://localhost:3000/styles/App.css */
.brand-link{cursor:pointer;width:auto;align-items:center;}
.nav-btn.btn.btn--red{margin-right:8px;}
.navbar-logo{height:50px;width:auto;object-fit:contain;}
.sec-wrap--beci .nav-link,.sec-wrap--beci .nav-link.w--current{color:var(--color-beci-blue);}
.sec-wrap--beci .nav-link:hover{color:var(--color-beci-blue);}
.sec-wrap--beci .nav-line{background-color:var(--color-beci-blue);}
.sec-wrap--beci .nav-btn{background-color:var(--color-beci-blue);border-color:var(--color-beci-blue);}
.sec-wrap--beci .nav-btn:hover{border-color:var(--color-beci-blue)!important;}
.sec-wrap--beci .nav-btn h4{color:#fff!important;}
.sec-wrap--beci .growing-div{background-color:var(--color-beci-blue-dark);}
.sec-wrap--beci .btn{background-color:var(--color-beci-blue-light);border-bottom-color:var(--color-beci-blue-dark);}
.sec-wrap--beci .btn:hover{background-color:var(--color-beci-blue-dark);}
/*! CSS Used from: Embedded */
@media (max-width: 991px){
.header-bottom .container{padding:0 15px;}
}
@media (max-width: 480px){
.header-bottom .container{padding:0 5px;}
}
.header-bottom{position:sticky;top:0;z-index:1001;background-color:#2800d7;}
.header-bottom .w-nav{background-color:#2800d7;}
.header-bottom .w-nav .nav-menu{margin-bottom:0;background-color:#2800d7;}
@media (max-width: 991px){
.header-bottom .w-nav .nav-menu{padding:10px 20px;}
}
.header-bottom .w-nav .nav-link-wrap{padding-left:0;padding-right:0;}
.header-bottom .w-nav .nav-link-wrap .nav-link{color:#fff;height:122px;display:flex;align-items:center;padding:0 1rem;margin:0;}
@media (max-width: 991px){
.header-bottom .w-nav .nav-link-wrap .nav-link{height:40px;width:100%;}
}
.header-bottom .w-nav .nav-link-wrap .nav-link:hover{background-color:#1f00a4;}
.header-bottom .w-nav .nav-link-wrap .nav-line{display:none!important;}
.header-bottom .w-nav .brand-link{height:122px;}
.header-bottom .w-nav .navbar-logo{height:90px;}
.header-bottom .w-nav .nav-btn{color:#f8f9fa;border-color:#f8f9fa;background-color:transparent;}
.header-bottom .w-nav .nav-btn:hover{background-color:#f8f9fa;border-color:#f8f9fa!important;}
.header-bottom .w-nav .nav-btn:hover h4{color:#212529!important;}
.header-bottom .w-nav .nav-btn .growing-div{display:none;}
@media (max-width: 991px){
.header-bottom .w-nav .w-nav-button{position:relative!important;width:56px;height:50px;padding:0;display:flex;align-items:center;justify-content:center;color:#fff;border:1px solid rgba(255, 255, 255, 0.1);}
.header-bottom .w-nav .nav__g-line{display:none;}
}
.flip-card{background-color:transparent;width:110px;height:110px;perspective:1000px;}
.flip-card-inner{position:relative;width:100%;height:100%;text-align:center;transition:transform 0.8s;transform-style:preserve-3d;}
.flip-card:hover .flip-card-inner{transform:rotateY(180deg);}
.flip-card-front,.flip-card-back{position:absolute;width:100%;height:100%;-webkit-backface-visibility:hidden;backface-visibility:hidden;}
.flip-card-front{display:flex;align-items:center;justify-content:center;}
.flip-card-back{background-color:#2800d7;color:white;transform:rotateY(180deg);}
.flip-card-back p{font-size:0.875rem;width:100%;height:100%;display:flex;align-items:center;justify-content:center;}
/*! CSS Used from: Embedded */
@media (max-width: 480px){
.sec-wrap--beci .w-nav-brand{padding-left:0;}
}
.sec-wrap--beci .nav-link-wrap--lang .nav-btn{border-width:1px;}
.sec-wrap--beci .nav-link-wrap--lang .nav-btn:hover .login-profile-icon{color:#212529;}
.sec-wrap--beci .nav-link-wrap--lang .login-profile-icon{display:none;}
@media (max-width: 991px){
.sec-wrap--beci .nav-link-wrap--lang{margin-right:0;margin-left:15px;}
.sec-wrap--beci .nav-link-wrap--lang .nav-btn{display:flex;margin-top:0;}
}
@media (max-width: 480px){
.sec-wrap--beci .nav-link-wrap--lang .nav-btn{width:56px;margin-left:0px;}
.sec-wrap--beci .nav-link-wrap--lang .nav-btn h4:not(.support__h){display:none;}
.sec-wrap--beci .nav-link-wrap--lang .nav-btn .login-profile-icon{display:flex;font-size:24px;}
}
.sec-wrap--beci .nav-link-login-menu-btn-wrap{display:flex;align-items:center;}
.sec-wrap--beci .header-bottom .nav__g-line{display:none;}
.sec-wrap--beci .menu-btn img{max-width:32px;}

.nav-auth-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn.btn-nav-auth {
  border: none;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  .nav__h {
    color: white;
  }
}
.btn.btn-nav-auth-fill {
  border-color: white;
  .nav__h {
    color: white;
  }
  &:hover {
    background-color: white;
    .nav__h {
      color: #2800d7;
    }
  }
}
.vertical-separator {
  width: 1px;
  background-color: white;
  height: 30px;
}

.account-menu {
  /*! CSS Used from: https://legal.beci.be/styles/normalize.css */
  svg:not(:root){overflow:hidden;}
  /*! CSS Used from: https://legal.beci.be/styles/webflow.css */
  *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  ul{margin-top:0px;margin-bottom:10px;padding-left:40px;}
  /*! CSS Used from: https://legal.beci.be/static/css/main.ca04ff1d.chunk.css */
  .MuiPaper-root{border-radius:0;box-shadow:0 10px 15px 0 hsla(0,0%,88.6%,.5);border:1px solid #f1f1f2;}
  .MuiPaper-root ul.MuiList-root{padding:20px;}
  .MuiPaper-root ul.MuiList-root li{border:1px solid #2802d6;padding:8px 8px 7px;display:flex;align-items:center;color:#2801d8;font-size:13px;font-family:"Rubik",sans-serif;font-weight:500;text-transform:uppercase;margin-bottom:15px;letter-spacing:0;min-width:170px;}
  .MuiPaper-root ul.MuiList-root li:last-child{margin-bottom:0;}
  .MuiPaper-root ul.MuiList-root li svg{display:block;font-size:24px;margin-right:9px;}
  /*! CSS Used from: Embedded */
  .MuiPaper-root{background-color: red; color:rgba(0, 0, 0, 0.87);transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;background-color:#fff;}
  .MuiPaper-rounded{border-radius:4px;}
  .MuiPaper-elevation8{box-shadow:0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);}
  /*! CSS Used from: Embedded */
  .MuiSvgIcon-root{fill:currentColor;width:1em;height:1em;display:inline-block;font-size:1.5rem;transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;flex-shrink:0;user-select:none;}
  /*! CSS Used from: Embedded */
  .MuiTouchRipple-root{top:0;left:0;right:0;bottom:0;z-index:0;overflow:hidden;position:absolute;border-radius:inherit;pointer-events:none;}
  /*! CSS Used from: Embedded */
  .MuiButtonBase-root{color:inherit;border:0;cursor:pointer;margin:0;display:inline-flex;outline:0;padding:0;position:relative;align-items:center;user-select:none;border-radius:0;vertical-align:middle;-moz-appearance:none;justify-content:center;text-decoration:none;background-color:transparent;-webkit-appearance:none;-webkit-tap-highlight-color:transparent;}
  .MuiButtonBase-root::-moz-focus-inner{border-style:none;}
  @media print{
  .MuiButtonBase-root{-webkit-print-color-adjust:exact;}
  }
  /*! CSS Used from: Embedded */
  .MuiPopover-paper{outline:0;position:absolute;max-width:calc(100% - 32px);min-width:16px;max-height:calc(100% - 32px);min-height:16px;overflow-x:hidden;overflow-y:auto;}
  /*! CSS Used from: Embedded */
  .MuiList-root{margin:0;padding:0;position:relative;list-style:none;}
  .MuiList-padding{padding-top:8px;padding-bottom:8px;}
  /*! CSS Used from: Embedded */
  .MuiMenu-paper{max-height:calc(100% - 96px);-webkit-overflow-scrolling:touch;}
  .MuiMenu-list{outline:0;}
  /*! CSS Used from: Embedded */
  .MuiListItem-root{width:100%;display:flex;position:relative;box-sizing:border-box;text-align:left;align-items:center;padding-top:8px;padding-bottom:8px;justify-content:flex-start;text-decoration:none;}
  .MuiListItem-dense{padding-top:4px;padding-bottom:4px;}
  .MuiListItem-gutters{padding-left:16px;padding-right:16px;}
  .MuiListItem-button{transition:background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;}
  .MuiListItem-button:hover{text-decoration:none;background-color:rgba(0, 0, 0, 0.04);}
  @media (hover: none){
  .MuiListItem-button:hover{background-color:transparent;}
  }
  /*! CSS Used from: Embedded */
  .MuiMenuItem-root{width:auto;overflow:hidden;font-size:1rem;box-sizing:border-box;min-height:48px;font-family:Sourcesanspro, sans-serif;font-weight:400;line-height:1.5;padding-top:6px;white-space:nowrap;padding-bottom:6px;}
  @media (min-width:600px){
  .MuiMenuItem-root{min-height:auto;}
  }
  .MuiMenuItem-dense{font-size:0.875rem;min-height:auto;font-family:Sourcesanspro, sans-serif;font-weight:400;line-height:1.43;}
  /*! CSS Used fontfaces */
  @font-face{font-family:'Rubik';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U6f4LnlY1PK6ycZg.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
  @font-face{font-family:'Rubik';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3Uzf4LnlY1PK6ycZg.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
  @font-face{font-family:'Rubik';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U1f4LnlY1PK6ycZg.woff2) format('woff2');unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;}
  @font-face{font-family:'Rubik';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U5f4LnlY1PK6ycZg.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
  @font-face{font-family:'Rubik';font-style:italic;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U3f4LnlY1PK6w.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
  @font-face{font-family:'Rubik';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-Light.ttf') format('truetype');font-weight:300;font-style:normal;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-Regular.ttf') format('truetype');font-weight:400;font-style:normal;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-Bold.ttf') format('truetype');font-weight:700;font-style:normal;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-SemiBold.ttf') format('truetype');font-weight:600;font-style:normal;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-ExtraLight.ttf') format('truetype');font-weight:200;font-style:normal;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-Italic.ttf') format('truetype');font-weight:400;font-style:italic;}
  @font-face{font-family:'Sourcesanspro';src:url('https://legal.beci.be/fonts/SourceSansPro-LightItalic.ttf') format('truetype');font-weight:300;font-style:italic;}

//   .MuiPaper-root {
//     border-radius: 0;
//     box-shadow: 0 10px 15px 0 rgba(226,226,226,0.5);
//     border: 1px solid #F1F1F2;

//     ul.MuiList-root {
//       padding: 20px;

//       li {
//         border: 1px solid #2802D6;
//         padding: 8px 8px 7px;
//         display: flex;
//         align-items: center;
//         color: #2801D8;
//         font-size: 13px;
//         font-family: 'Rubik', sans-serif;
//         font-weight: 500;
//         text-transform: uppercase;
//         margin-bottom: 15px;
//         letter-spacing: 0px;
//         min-width: 170px;

//         &:last-child {
//           margin-bottom: 0;
//         }

//         svg {
//           display: block;
//           font-size: 24px;
//           margin-right: 9px;
//         }
//       }
//     }
//   }
}