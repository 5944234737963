@mixin xxxs {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin xxs {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1200px) {
    @content;
  }
}