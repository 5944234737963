.landing {

  .faq {
    max-width: 1200px;
    padding: 0 30px;
    margin: 0 auto;

    @include xs {
      padding-bottom: 50px;
    }

    &__head {
      text-align: center;
      margin-bottom: 44px;

      @include xs {
        margin-bottom: 20px;
      }
    }

    &__body {
      margin-bottom: 63px;

      @include xs {
        margin-bottom: 30px;
      }

      &_questions {

        .question {
          cursor: pointer;
          padding: 25px 0;
          border-bottom: 1px solid #C4C4C4;

          @include xs {
            padding: 20px 0;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.22;
            color: #5EB258;
            text-align: left;
            transition: color .3s ease;

            @include xs {
              font-size: 12px;
            }
          }

          &:hover {

            p {
              color: darken(#5EB258, 10);
            }
          }
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {

        &:first-child {
          // margin-right: 63px;
        }
      }

      @include xs {
        flex-direction: column;

        .button:first-child {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}