.experts {
  padding: 95px 90px 73px;
  background-color: #ccc;
  color: #fff;
  position: relative;
  background-image: url('../../_assets/slider_bg.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  
  @media (min-width: 1440px) {
    width: calc(100vw - 17px);
    margin-left: -108px;
  }

  @include md {
    padding: 60px 40px;
  }
  @include sm {
    padding: 26px 29px;
  }
  @include xxs {
    background-position: center right -60px;
  }

  &__overlay {
    background: linear-gradient(89.91deg, #000000 -0.76%, rgba(0, 0, 0, 0.5) 51.68%, rgba(255, 255, 255, 0) 99.92%);
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__body {
    padding-right: 25%;
    position: relative;
    z-index: 1;
    max-width: 1260px;
    margin: 0 auto;

    &_subtitle {
      font-size: 26px;
      font-weight: 500;
      line-height: 1.22;
      text-align: left;
      margin-bottom: 56px;

      @include md {
        font-size: 22px;
        margin-bottom: 40px;
      }
      @include sm {
        font-size: 18px;
      }
      @include xs {
        font-size: 12px;
      }
    }

    .our-experts {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.22;
      text-align: left;
      margin-bottom: 11px;

      @include md {
        font-size: 16px;
      }
      @include sm {
        font-size: 12px;
      }
      @include xxs {
        font-size: 9px;
      }
    }

    @include sm{
      padding-right: 20%;
    }
  }

  h2.heading-1 {
    color: inherit;
    margin-bottom: 44px;
    max-width: 790px;

    @include md {
      margin-bottom: 30px;
    }
    @include sm {
      margin-bottom: 15px;
    }
    @include xs {
      margin-bottom: 8px;
    }
  }

  h3 {
    margin-bottom: 46px;

    @include md {
      margin-bottom: 30px;
    }
    @include sm {
      margin-bottom: 20px;
      font-weight: 400;
    }
    @include xs {
      font-size: 14px;
      margin-bottom: 14px;
    }
    @include xxs {
      font-size: 10px;
    }
  }

  &__slider {
    position: relative;
    max-width: 534px;
    
    &_slide {

      h4 {
        margin-bottom: 16px;

        @include md {
          margin-bottom: 14px;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.22;
        font-weight: 400;
        text-align: left;

        @include md {
          font-size: 16px;
        }
        @include sm {
          font-size: 14px;
        }
        @include xs {
          font-size: 12px;
        }
        @include xxs {
          font-size: 10px;
        }
      }
    }

    &_navigation {
      margin-top: 33px;
      display: flex;
      align-items: center;

      @include sm {
        margin-top: 23px;
      }

      .icon-button {

        &:first-child {
          margin-right: 21px;

          @include md {
            margin-right: 18px;
          }
        }
      }
    }

  }

  &__slider-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;

    @include md {
      justify-content: flex-end;
      bottom: 0;
    }

    .bullet {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: #fff;
      opacity: 0.5;
      margin: 0 6px;
      cursor: pointer;

      @include md {
        width: 8px;
        height: 8px;
        margin: 0 0 0 8px;
      }
    }

    .bullet-active {
      opacity: 1;
    }
  }

  &__slider-2 {

    &_slide {

      .name-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .name {
          font-size: 24px;
          font-weight: 500;
          line-height: 1.22;

          @include md {
            font-size: 18px;
          }
          @include sm {
            font-size: 16px;
          }
          @include xs {
            font-size: 14px;
          }
        }

        .shapes {
          flex: 1;
          display: flex;
          align-items: center;
          margin-left: 14px;

          &__1 {
            flex: 1;
            height: 1.5px;
            background-color: #fff;
          }

          &__2 {
            width: 20px;
            height: 20px;
            border: 2px solid #fff;
            border-radius: 10px;

            @include xs {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .description {
        margin-bottom: 15px;
        
        p {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.22;
          text-align: left;

          @include md {
            font-size: 14px;
          }
          @include sm {
            font-size: 12px;
          }
        }
      }

      .categories {
        margin-bottom: 20px;

        span {
          background-color: #A6A6A6;
          border-radius: 20px;
          display: inline-flex;
          align-items: center;
          padding: 0 10px;
          height: 26px;
          margin-right: 12px;
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 1.23;

          @include sm {
            font-size: 12px;
          }

          @include xs {
            background-color: #000;
          }
        }
      }
    }
  }

  &--2 {
    margin-top: -50px;

    h2.heading-1 {
      margin-bottom: 22px;
    }

    @include xs {
      background-position: center right -100px;

      .experts__body {

        .button {
          font-size: 12px;
          padding: 0 12px;
        }
      }

      .experts__slider-pagination {
        bottom: -5px;
        right: -10px;
        left: auto;
      }
    }

    .experts__slider-pagination {
      justify-content: flex-end;
    }
  }
}