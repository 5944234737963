.landing {

  .packages {
    background-color: #5EB258;
    padding: 69px 19px;

    @media (min-width: 1440px) {
      width: calc(100vw - 17px);
      margin-left: -108px;
    }

    @include sm {
      padding: 24px 19px 40px;
    }

    &__head {
      margin-bottom: 54px;
      text-align: center;

      @include sm {
        margin-bottom: 26px;
      }

      h3 {
        color: #fff;
      }
    }

    &__boxes {
      margin: 0 auto;
      max-width: 1052px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__box {
      width: 45.24%;
      background-color: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      padding: 37px 42px 42px;
      margin-bottom: 70px;

      @include sm {
        width: 100%;
        margin-bottom: 25px;
      }

      p {
        text-align: left;
      }

      &_top {
        margin-bottom: 47px;
        position: relative;
        padding-right: 42%;

        @include sm {
          margin-bottom: 12px;
        }
        @include xxs {
          padding-right: 0;
        }

        .type {
          font-size: 18px;
          font-weight: 800;
          line-height: 1.22;
        }

        .popular {
          min-width: 135px;
          width: 42%;
          height: 28px;
          background-color: #C1E0C7;
          border-radius: 20px;
          position: absolute;
          top: -10px;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #5EB258;

          @include md {
            font-size: 11px;
          }

          @include xxs {
            // display: none;
            top: -30px;
            right: -30px;
          }
        }
      }

      .price {
        font-size: 50px;
        line-height: 1.22;
        font-weight: 700;
        margin-bottom: 13px;

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }

      .offer {
        font-size: 16px;
        font-weight: 700;
        opacity: 0.5;
        margin-bottom: 15px;
      }

      &_desc {
        margin-bottom: 35px;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.357;
          opacity: 0.5;

          span {
            display: block;
          }
        }

        @include xxs {
          margin-bottom: 19px;

          p {
            font-size: 12px;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          font-size: 14px;
          line-height: 1.22;
          color: #5EB258;
          margin-bottom: 15px;
          padding-left: 40px;
          position: relative;

          @include xxs {
            font-size: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          svg {
            display: block;
            font-size: 28px;
            position: absolute;
            top: -4px;
            left: 0;
          }

          &.not-available {
            color: #000;
            opacity: 0.5;
          }
        }
      }
    }

    &__foot {
      display: flex;
      justify-content: center;

      @include sm {
        margin-top: 15px;
      }

      .button {
        
      }
    }
  }
}