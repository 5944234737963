@import './variables.scss';

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-layout-grid.grid--home {
  max-width: 1060px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

body {
  font-family: Montserrat, sans-serif;
  color: #030f1c;
  font-size: 17px;
  line-height: 1.3em;
  letter-spacing: -0.24px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #030f1c;
  font-size: 48px;
  line-height: 1.3em;
  font-weight: 700;
  letter-spacing: -0.58px;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 34px;
  line-height: 1.3em;
  font-weight: 700;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 28px;
  line-height: 1.3em;
  font-weight: 400;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 17px;
  line-height: 1.3em;
  font-weight: 600;
}

h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #9bb1c8;
  font-size: 13px;
  line-height: 1.3em;
  font-weight: 400;
  letter-spacing: -0.06px;
}

h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 11px;
  line-height: 1.3em;
  font-weight: 400;
}

p {
  margin-bottom: 0px;
  text-align: center;
}

a {
  color: #030f1c;
  text-decoration: none;
}

.wrapper {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.nav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding-top: 24px;
  padding-bottom: 12px;
  background-color: #fff;
}

.nav.nav--profile {
  padding-bottom: 24px;
}

.brand {
  position: absolute;
  left: 24px;
  top: 0px;
  right: auto;
  bottom: 0px;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.brand.w--current {
  left: 24px;
}

.brand__img {
  padding-top: 0px;
  padding-bottom: 0px;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1392px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container.container--nav {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 68px;
}

.container.container--footer._2 {
  padding-top: 42px;
  padding-bottom: 42px;
}

.container.container--hero {
  position: relative;
  min-height: 50vh;
  padding-right: 24px;
  padding-left: 24px;
}

.container.container--hero.up {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.container.container--hero.left-align {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.container.container--nav-green {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 68px;
}

.nav-links-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-links-wrap.nav-links-wrap--mid {
  width: 58%;
}

.nav-links-wrap.nav-links-wrap--green {
  width: 50%;
}

.nav-links-wrap.nav-links-wrap--green2 {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav-links-wrap.right-align {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav__link {
  position: relative;
  z-index: 1;
  margin-right: 24px;
  padding: 12px 17px;
  border-radius: 50px;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
}

.nav__link:hover {
  background-color: #efefef;
}

.nav__link.w--current {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $primary-color;
  color: #fff;
}

.nav__link.nav__link--contact {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: $primary-color;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #fff;
}

.nav__link.nav__link--contact:hover {
  background-color: $primary-color;
}

.nav__link.nav__link--green {
  padding-left: 20px;
  color: #2707df;
}

.nav__link.nav__link--margin {
  margin-right: 0px;
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.nav__link.nav__link--dropdown {
  width: 100%;
  margin-left: 18px;
  padding: 8px 18px 8px 16px;
  border-radius: 6px;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #2707df;
}

.nav__link.nav__link--dropdown.w--open {
  margin-left: 18px;
  padding-right: 18px;
}

.nav__link.nav__link--white {
  color: #fff;
}

.nav__link.nav__link--white:hover {
  background-color: transparent;
}

.nav__link.nav__link--white.w--current {
  background-color: transparent;
  color: #fff;
}

.nav__link.nav__link--white.nav__link--mr {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 16px;
  padding-left: 16px;
}

.nav__link.nav__link--white.nav__link---position {
  position: absolute;
  left: 24px;
  top: auto;
  right: auto;
  bottom: auto;
  margin-right: 0px;
  border-radius: 0px;
  background-color: transparent;
  opacity: 0;
}

.nav__link.nav__link--mr {
  margin-right: 24px;
  padding-right: 10px;
  padding-left: 10px;
}

.nav__link.mr0 {
  margin-right: 0px;
}

.nav__link.position {
  position: static;
  margin-left: 0px;
}

.nav__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav__menu.nav__menu--green {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sec {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 115px;
  padding-bottom: 115px;
}

.sec.sec--footer {
  padding: 0 !important;
  border: 1px none #000;
  background-color: #f2f2f2;
}

.hero-title {
  max-width: 650px;
}

.hero-subtitle {
  max-width: 600px;
  margin-top: 38px;
}

.hero-subtitle.left-align {
  text-align: left;
}

.gray-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  width: 100%;
  height: 55%;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 6px;
  background-color: #efefef;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
}

.gray-bg.calendar {
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
}

.nav__title {
  position: relative;
  z-index: 1;
  margin-right: 4px;
}

.shadow-box {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  box-shadow: 0 -8px 16px 10px rgba(56, 96, 165, 0.15);
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
}

.search-wrap {
  position: relative;
  margin-top: 50px;
  margin-bottom: 40px;
}

.search-wrap.step1 {
  margin-bottom: 0px;
}

.search-box {
  display: inline-block;
  width: 100%;
  height: 56px;
  margin-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 80px;
}

.search-input {
  width: 583px;
  height: 100%;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  border: 1px solid #d7dfe5;
  border-radius: 80px;
  -webkit-transition: box-shadow 300ms ease-in-out, border-color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, border-color 300ms ease-in-out;
}

.search-input:hover {
  border-style: solid;
  border-color: #3ca545;
}

.search-input:active {
  box-shadow: 0 0 0 4px rgba(62, 164, 71, 0.4);
}

.search-input:focus {
  box-shadow: 0 0 0 4px rgba(62, 164, 71, 0.4);
}

.search-input::-webkit-input-placeholder {
  color: #9bb1c8;
}

.search-input:-ms-input-placeholder {
  color: #9bb1c8;
}

.search-input::-ms-input-placeholder {
  color: #9bb1c8;
}

.search-input::placeholder {
  color: #9bb1c8;
}

.search__btn-wrap {
  position: absolute;
  left: auto;
  top: 0%;
  right: 5px;
  bottom: 0%;
  width: 158px;
  height: 47px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 12px;
  border-radius: 80px;
  background-color: $primary-color;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}

.search__btn-wrap:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.btn {
  width: 100%;
  height: 47px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 80px;
  background-color: transparent;
}

.btn.btn--search {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.btn.btn--grid {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: box-shadow 200ms ease-in-out;
  transition: box-shadow 200ms ease-in-out;
  font-size: 14px;
}

.btn.btn--grid:hover {
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.3);
}

.search-icon {
  width: 21px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 12px;
}

.subtitle {
  max-width: 415px;
  margin-top: 32px;
}

.grid {
  width: 100%;
  margin-top: 80px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: minmax(360px, 1fr) minmax(360px, 1fr);
  grid-template-rows: minmax(360px, 1fr) minmax(360px, 1fr);
}

.grid.grid--home {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  -ms-grid-rows: minmax(300px, 1fr) minmax(300px, 1fr);
  grid-template-rows: minmax(300px, 1fr) minmax(300px, 1fr);
}

.grid.grid--home .img-text-wrap h3 {
  font-size: 22px;
  padding-bottom: 15px;
}

.grid.grid--home .img-text-wrap h3 span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 97%;
  white-space: nowrap;
}

.grid.grid--home .img-text-wrap .grid__desc {
  margin-top: 0;
}

.grid.grid--step1 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: minmax(280px, 1fr) minmax(280px, 1fr);
  grid-template-rows: minmax(280px, 1fr) minmax(280px, 1fr);
}

// .grid.grid--d-hidden {
//   display: none;
// }

.img-text-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 24px;
  -webkit-transition: box-shadow 200ms ease-in-out;
  transition: box-shadow 200ms ease-in-out;
  cursor: pointer;
}

.img-text-wrap:active {
  box-shadow: 0 0 0 4px hsla(0, 0%, 62.4%, 0.4);
}

.overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  background-color: #000;
  opacity: 0.75;
}

.img-box {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-box {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.grid--home .text-box {
  padding: 60px 32px 30px;
}

@media (max-width: 991px) {
  .grid--home .text-box {
    padding-top: 30px;
  }
  .grid.grid--home {
    -ms-grid-rows: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
    grid-template-rows: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
  }
}

@media (max-width: 480px) {
  .grid.grid--home {
    -ms-grid-rows: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
    grid-template-rows: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
  }
}

.grid__title-box {
  width: 100%;
  position: relative;
  z-index: 1;
}

.h3.h3--white {
  margin-top: -10px;
  padding-bottom: 30px;
  color: #fff;
}

.h3.h3--white.h3--small {
  position: static;
  bottom: 0px;
  overflow: visible;
  height: 40px;
  float: none;
  clear: none;
  font-size: 20px;
  line-height: 1.1em;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
}

.h3.h3--bold {
  margin-bottom: 56px;
  font-weight: 700;
  text-align: center;
}

.h3.h3--bold.h3--blue {
  margin-top: 68px;
  margin-bottom: 0px;
  color: #9bb1c8;
}

.h3.h3--bold.h3--left {
  margin-bottom: 28px;
  text-align: left;
}

.h3.h3--green {
  color: #2707df;
}

.grid__desc {
  margin-top: 20px;
  color: #fff;
  text-align: left;
}

.grid__desc.step1 {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.2em;
}

.btn-wrap {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 254px;
  height: 47px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 80px;
  background-color: #727c88;
}

.btn-wrap.btn-wrap--short {
  width: 95%;
}

.text-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #354455;
}

.features-wrap {
  width: 100%;
  padding-top: 86px;
  // padding-bottom: 176px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: -12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.row.row--short {
  display: none;
  max-width: 921px;
  margin-right: auto;
  margin-left: auto;
}

.row.row--short2 {
  max-width: 808px;
  margin-right: auto;
  margin-left: auto;
}

.row.row--center {
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.row.row--stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row.row--hidden {
  display: none;
}

.row.home-hero {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row.dropdown {
  height: 100%;
  margin: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.row.row-formules {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.col {
  width: 50%;
  padding: 12px;
}

.col.col--short {
  width: 33.33%;
}

.col.col--short.col--footer {
  padding-left: 0px;
}

.col.col--short1 {
  width: 33.33%;
}

.col.col--short1.col--footer {
  width: 26%;
  padding-left: 0px;
}

.col.col--short2 {
  width: 33.33%;
}

.col.col--short2.col--footer {
  width: 38%;
  padding-left: 0px;
}

.col.col--short3 {
  width: 33.33%;
}

.col.col--short3.col--footer {
  width: 38%;
  padding-right: 0px;
  padding-left: 12px;
}

.col.col--sign-up {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 580px;
  padding: 210px 72px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 87.1%, 0.25);
}

.col.col--sign-up.col--sign-up--white {
  padding: 68px 72px;
  background-color: #fff;
}

.col.col--request {
  position: relative;
  width: 37%;
  padding-top: 0px;
  padding-right: 57px;
  padding-bottom: 24px;
  border-right: 1px solid #dedede;
}

.col.col--request-long {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 63%;
  padding: 0px 12px 12px 57px;
}

.col.footer__col {
  width: 100%;
}

.col.table__col {
  width: 25%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.col.hero-col {
  width: 60%;
}

.col.hero-col-sm {
  width: 40%;
}

.col.col__formules {
  width: 33.33%;
}

.feature-img {
  width: 85px;
  height: 85px;
  margin-bottom: 20px;
}

.col__cont-wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col__cont-wrap.col__cont-wrap--vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col__cont-wrap.col__cont-wrap--vertical.col__cont-wrap--left {
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.col__cont-wrap.col__cont-wrap--relative {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.h4.h4--blue {
  margin-bottom: 90px;
  color: #9bb1c8;
  text-align: center;
}

.h4.h4--green {
  color: #2707df;
  font-size: 15px;
  font-weight: 700;
}

.h4.h4--green.h4--centered {
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.h4.h4--green.h4--m {
  margin-top: 16px;
  margin-bottom: 16px;
}

.h4.h4--request {
  max-width: 300px;
  margin-bottom: 35px;
}

.h4.h4--gray {
  display: inline-block;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #2707df;
  color: #727c88;
}

.h4.h4--table {
  display: none;
}

.p.col__p {
  max-width: 392px;
  margin-top: 32px;
}

.p.col__p.p--small {
  font-size: 15px;
}

.p.p--small {
  margin-top: 16px;
  font-size: 15px;
}

.p.p--left {
  text-align: left;
}

.p.p--left.p--mb {
  margin-bottom: 16px;
}

.services-wrap {
  width: 100%;
  padding-bottom: 0px;
}

.circles-wrap {
  margin-bottom: 80px;
}

.circles-wrap--home {
  margin-bottom: 40px;
}

.circles-wrap.signup {
  position: relative;
  width: 620px;
  height: 211px;
  margin: 40px auto 20px;
}

.expert-box {
  position: absolute;
  left: -50px;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 121px;
  height: 121px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.expert-box.circle-box--2 {
  position: static;
  left: auto;
  top: 0%;
  right: 20px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--2.signup {
  width: 90px;
  height: 90px;
}

.expert-box.circle-box--3 {
  position: static;
  left: 50px;
  top: auto;
  right: 0%;
  bottom: -80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #2707df;
}

.expert-box.circle-box--3.signup {
  width: 90px;
  height: 90px;
}

.expert-box.circle-box--4 {
  position: static;
  left: 0px;
  top: 140px;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--5 {
  position: static;
  left: 50px;
  top: 20px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--6 {
  position: static;
  left: 40px;
  top: auto;
  right: auto;
  bottom: -70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--7 {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--8 {
  position: static;
  left: auto;
  top: auto;
  right: 0%;
  bottom: -60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--8.signup {
  width: 90px;
  height: 90px;
}

.expert-box.circle-box--9 {
  position: static;
  left: 50px;
  top: 50px;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.circle-box--9.signup {
  width: 90px;
  height: 90px;
}

.expert-box.m-hidden {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.expert-box.d-hidden {
  display: none;
}

.expert-box.circle-box--10 {
  left: auto;
  top: auto;
  right: 200px;
  bottom: -80px;
  display: block;
}

.expert-box.signup {
  left: 40px;
  width: 90px;
  height: 90px;
}

.formules-wrap {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 96px;
}

.formules-wrap--home {
  padding-top: 0 !important;
}

.price-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 46px;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.h1.h1--price {
  margin-right: 16px;
}

.h1.h1--center {
  text-align: center;
}

.col__h5 {
  max-width: 344px;
  margin-top: 16px;
}

.list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 54px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.list__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.list__img {
  margin-right: 9px;
}

.list__img.list__img--opacity {
  opacity: 0;
}

.list__img.list__img--gray {
  width: 32px;
  opacity: 0.6;
  -webkit-filter: contrast(0%);
  filter: contrast(0%);
}

.list__h5 {
  max-width: 344px;
}

.link-box {
  position: relative;
  z-index: 1;
  padding: 24px 18px;
  cursor: pointer;
}

.link-box.nav__link--contact {
  margin-left: 20px;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #2707df;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #fff;
}

.link-box.nav__link--contact:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.link-box.nav__link--green {
  color: #2707df;
}

.link-box.nav__link--margin {
  margin-right: 12px;
}

.link-box.link-box--submit {
  width: 100%;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #2707df;
  border-radius: 6px;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #2707df;
}

.link-box.link-box--submit:hover {
  background-color: #2707df;
  color: #fff;
  font-weight: 600;
}

.link-box.link-box--submit.link-box--green {
  background-color: #2707df;
  color: #fff;
  font-weight: 600;
}

.link-box.link-box--submit.link-box--green:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.link-box.link-box--sign-up {
  width: 100%;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #2707df;
  border-radius: 6px;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #2707df;
}

.link-box.link-box--sign-up:hover {
  background-color: #2707df;
  color: #fff;
  font-weight: 600;
}

.link-box.link-box--sign-up.link-box--green {
  max-width: 256px;
  margin-top: 38px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #2707df;
  color: #fff;
  font-weight: 600;
}

.link-box.link-box--sign-up.link-box--green:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.link-box.link-box--sign-up.link-box--green.center {
  width: 382px;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
}

.link-box__title {
  position: relative;
  z-index: 1;
  -webkit-transition: color 200ms ease-in-out;
  transition: color 200ms ease-in-out;
}

.col__formules-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 6px;
  box-shadow: 0 15px 30px -10px rgba(56, 96, 165, 0.15);
}

.col__formules-wrap.col__cont-wrap--vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col__formules-wrap.col__cont-wrap--vertical.col__cont-wrap--left {
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.col__formules-wrap.col__cont-wrap--relative {
  position: relative;
  height: 240px;
}

.col__formules-wrap._1 {
  height: 100%;
}

.best-choice-wrap {
  position: absolute;
  left: auto;
  top: 24px;
  right: 24px;
  bottom: auto;
  padding: 6px 16px;
  border-radius: 20px;
  background-color: rgba(39, 7, 223, 0.3);
}

.h5.h5--green {
  color: #2707df;
}

.testimonials-wrap {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 96px;
}

.slider {
  height: 100%;
  background-color: transparent;
}

.slider__circle-box {
  width: 80px;
  height: 80px;
  margin-right: auto;
  margin-bottom: 28px;
  margin-left: auto;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider__circle-box.circle-box--2 {
  left: auto;
  top: 0%;
  right: 20px;
  bottom: 0%;
  margin: auto 0px;
}

.slider__circle-box.circle-box--3 {
  left: 50px;
  top: auto;
  right: 0%;
  bottom: -80px;
}

.slider__circle-box.circle-box--4 {
  left: 0px;
  top: 140px;
  right: auto;
  bottom: 0%;
  margin: auto 0px;
}

.slider__circle-box.circle-box--5 {
  left: 50px;
  top: 20px;
  right: auto;
  bottom: auto;
}

.slider__circle-box.circle-box--6 {
  left: 40px;
  top: auto;
  right: auto;
  bottom: -70px;
  margin: auto 0px;
}

.slider__circle-box.circle-box--7 {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin: auto 0px;
}

.slider__circle-box.circle-box--8 {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -60px;
}

.slider__circle-box.circle-box--9 {
  left: 50px;
  top: 50px;
  right: 0%;
  bottom: 0%;
  margin-top: auto;
  margin-bottom: auto;
}

.mask {
  width: 80%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

.slider__cont {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider__cont.col__cont-wrap--vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.slider__cont.col__cont-wrap--vertical.col__cont-wrap--left {
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.slider__cont.col__cont-wrap--relative {
  position: relative;
  height: 240px;
}

.slider__cont-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-bottom: 75px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.arrow-box {
  position: absolute;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.slider__arrow-img {
  z-index: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.slider__arrow-img.slider__arrow-img--left {
  left: 15px;
  top: 0%;
  right: auto;
  bottom: 0%;
  -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.slider__arrow-wrap {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #b7b7b7;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}

.slider__arrow-wrap.slider__arrow-wrap--left {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  -webkit-transition-property: none;
  transition-property: none;
}

.slider__arrow-wrap.slider__arrow-wrap--left:hover {
  background-color: #b7b7b7;
}

.slider__arrow-wrap.slider__arrow-wrap--right {
  -webkit-transition-property: none;
  transition-property: none;
}

.partners-wrap {
  width: 100%;
  padding-bottom: 161px;
}

.partners__logo-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.partner-logo {
  max-width: 247px;
}

.footer__link {
  margin-right: 24px;
  font-size: 15px;
}

.footer__link.footer__link--3 {
  margin-right: 0px;
}

.footer__link.footer__link--green {
  margin-right: 0px;
  color: #2707df;
}

.footer__logo.footer__logo--opacity {
  opacity: 0;
}

.footer__cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__cont.footer__cont--right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__h6 {
  color: #9bb1c8;
}

.social-links__wrap {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 4px;
  margin-left: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #dedede;
  cursor: pointer;
}

.social-logo {
  width: 16px;
}

.nav__link2 {
  position: relative;
  z-index: 1;
  padding: 24px 18px;
  cursor: pointer;
}

.nav__link2.nav__link--contact {
  margin-left: 20px;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #2707df;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #fff;
}

.nav__link2.nav__link--contact:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.nav__link2.nav__link--green {
  color: #2707df;
}

.nav__link2.nav__link--margin {
  margin-right: 12px;
}

.expert-desc {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 1;
  display: block;
  width: 290px;
  height: 270px;
  margin-top: 200px;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 15px 30px -10px rgba(56, 96, 165, 0.15);
  opacity: 0;
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.expert__p {
  margin-top: 15px;
  font-size: 14px;
  text-align: left;
}

.expert__h4 {
  font-size: 18px;
}

.icon {
  width: 10px;
  margin-right: 4px;
}

.text-block {
  margin-right: 8px;
}

.dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dropdown__list.w--open {
  margin-top: 54px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(56, 96, 165, 0.15);
}

.dropdown__link {
  padding-right: 0px;
  padding-left: 0px;
  color: #2707df;
  cursor: pointer;
}

.d-cont-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 772px;
  margin: 78px auto 64px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sec__h2 {
  font-size: 25px;
  line-height: 1.3em;
  letter-spacing: 1px;
}

.d-cont__p {
  margin-top: 20px;
  margin-bottom: 50px;
}

.title-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid hsla(0, 0%, 87.1%, 0.75);
  text-decoration: none;
  cursor: pointer;
}

.title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 34px;
  padding-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.7;
}

.title-wrap.d-title {
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.title-wrap.title-wrap--left {
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
  opacity: 1;
}

.experts-h4 {
  color: #030f1c;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.experts-h4.cont__h4--authors {
  color: salmon;
}

.arrow-img {
  margin-right: 0px;
  padding-right: 0px;
  color: transparent;
}

.accordion-wrap {
  width: 100%;
}

.a-hero__text {
  width: 100%;
  height: 100%;
}

.experts__p {
  display: block;
  width: 100%;
  height: 0px;
  margin-top: 30px;
  opacity: 0;
  color: #030f1c;
  font-weight: 400;
  text-align: left;
}

.experts-link {
  z-index: 2;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: salmon;
  text-decoration: none;
  cursor: pointer;
}

.experts-title {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px none #d7dfe5;
}

.form__label {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.form__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form__row.payment {
  width: 100%;
}

.form__row.form__row--pt {
  padding-top: 32px;
}

.form {
  position: relative;
  max-width: 605px;
  background-color: transparent;
}

.form.request-detail {
  margin-top: 46px;
}

.c-form__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: #00008f;
  text-decoration: none;
  cursor: pointer;
}

.c-form__btn.btn--full {
  width: 100%;
  border-style: none;
  background-color: #fafafa;
  -webkit-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out;
  color: salmon;
  text-transform: uppercase;
}

.c-form__btn.btn--full.pop-up__btn {
  margin-top: 40px;
  background-color: salmon;
  color: #f5f5f5;
}

.c-form__btn.btn--full.pop-up__btn:hover {
  background-color: #ec4d33;
}

.c-form__wrap2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form__btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form__btn-wrap.sign-up {
  margin-top: 30px;
}

.c-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #00008f;
  text-decoration: none;
  cursor: pointer;
}

.c-btn.btn--red {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-color: salmon;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.c-btn.btn--red:hover {
  border-color: #ec4d33;
}

.c-form__h5 {
  margin-bottom: 15px;
  color: rgba(51, 51, 51, 0.4);
  font-weight: 400;
  letter-spacing: 0.5px;
}

.nav__h {
  position: relative;
  z-index: 3;
  color: #f07662;
  font-size: 13px;
  line-height: 1.3px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav__h:hover {
  color: #ec4d33;
}

.c-form__text-field2 {
  width: 150px;
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(51, 51, 51, 0.1);
  border-radius: 2px;
}

.form__field-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  margin-top: 51px;
  margin-right: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form__field-wrap.form__field-wrap--right {
  margin-right: 0px;
  margin-left: 10px;
}

.form__field-wrap.form__field-wrap--left {
  margin-right: 10px;
  margin-left: 0px;
}

.form__field-wrap.sign-up {
  margin-top: 40px;
}

.form__field-wrap.form__field-wrap--stretch {
  width: 100%;
}

.form__field-wrap.payment {
  width: 100%;
  margin-right: 0px;
}

.form__field-wrap.form__field-wrap--ml {
  margin-right: 0px;
  margin-left: 12px;
}

.form__field-wrap.width {
  width: 48%;
}

.form__field-wrap.form__field-wrap--sm {
  width: 30%;
  margin-right: 0px;
  margin-left: 12px;
}

.c-btn-line-wrap {
  width: 35%;
}

.form__text-field {
  margin-bottom: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: rgba(51, 51, 51, 0.1) rgba(51, 51, 51, 0.1) rgba(51, 51, 51, 0.1) #ddd;
}

.form__text-field:focus {
  border-bottom-color: #2707df;
}

.red-line {
  width: 100%;
  height: 2px;
  background-color: #ec4d33;
}

.growing-div {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  width: 0%;
  height: 500%;
  background-color: #ec4d33;
  opacity: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tab-wrap {
  width: 100%;
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 71px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.tabs__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tabs__link {
  max-width: 239px;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  color: #2707df;
  cursor: pointer;
}

.tabs__link:hover {
  border-radius: 3px;
  // background-color: rgba(39, 7, 223, 0.8);
  background-color: rgba(39, 7, 223, 0.8);
  color: #fff;
}

.tabs__link.w--current {
  max-width: 239px;
  padding: 16px;
  border-radius: 3px;
  background-color: #2707df;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.tabs__content {
  width: 70%;
  padding-left: 48px;
}

.form__title.form__title--mt {
  margin-top: 70px;
}

.form__subtitle {
  margin-top: 40px;
  font-size: 15px;
}

.form__img {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 8px;
  width: 40px;
}

.form__img.form__img--position {
  position: static;
  margin-right: 32px;
}

.form__btn {
  min-width: 180px;
  margin-top: 66px;
  padding: 16px 32px;
  border-radius: 3px;
  background-color: hsla(0, 0%, 87.1%, 0.5);
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  color: #ff794f;
  font-size: 15px;
  cursor: pointer;
}

.form__btn:hover {
  background-color: #dedede;
  font-weight: 400;
}

.form__btn.payment {
  width: 100%;
  margin-top: 0px;
  background-color: #2707df;
  color: #fff;
}

.form__btn.payment:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.form__btn.form__btn--green {
  background-color: #2707df;
  color: #fff;
  font-weight: 800;
}

.breadcrumbs-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #F7F6F6;
  align-self: flex-start;
  padding: 6px 11px 6px 0;
  position: relative;
}

.breadcrumbs-wrap.breadcrumbs-wrap--stretch {
  width: 100%;
}

.breadcrumbs-wrap__bg-left {
  position: absolute;
  width: 2000px;
  height: 100%;
  background-color: #F7F6F6;
  top: 0;
  left: -2000px;
}

.breadcrumbs-wrap__bg-right {
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 100%;
  overflow: hidden;
}

.breadcrumbs-wrap__bg-right_inner {
  position: absolute;
  width: 20px;
  height: 150%;
  background-color: #F7F6F6;
  top: -10px;
  right: 10px;
  transform: rotate(15deg);
}

.breadcrumb-link {
  display: inline-block;
  color: #020F1D;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
}

.breadcrumb-link.w--current {
  /* color: rgba(127, 146, 162, 0.5); */
}

.breadcrumb-link.breadcrumb-link--np {
  /* margin-right: 8px; */
  padding-right: 0px;
  padding-left: 0px;
}

.breadcrumps-p {
  display: inline-block;
  color: #000;
  font-size: 11px;
  line-height: 18px;
  margin: 0 16px;
}

.breadcrumps-p img {
  display: block;
}

.experts-subtitle {
  margin-top: 10px;
  font-size: 12px;
}

.experts-circle-box {
  width: 95px;
  height: 95px;
  margin-right: 48px;
  border-radius: 50%;
  background-color: lightgray;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.experts-circle-box.circle-box--2 {
  left: auto;
  top: 0%;
  right: 20px;
  bottom: 0%;
  margin: auto 0px;
}

.experts-circle-box.circle-box--3 {
  left: 50px;
  top: auto;
  right: 0%;
  bottom: -80px;
}

.experts-circle-box.circle-box--4 {
  left: 0px;
  top: 140px;
  right: auto;
  bottom: 0%;
  margin: auto 0px;
}

.experts-circle-box.circle-box--5 {
  left: 50px;
  top: 20px;
  right: auto;
  bottom: auto;
}

.experts-circle-box.circle-box--6 {
  left: 40px;
  top: auto;
  right: auto;
  bottom: -70px;
  margin: auto 0px;
}

.experts-circle-box.circle-box--7 {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin: auto 0px;
}

.experts-circle-box.circle-box--8 {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -60px;
}

.experts-circle-box.circle-box--9 {
  left: 50px;
  top: 50px;
  right: 0%;
  bottom: 0%;
  margin-top: auto;
  margin-bottom: auto;
}

.expert {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 42px;
  padding-bottom: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dedede;
}

.help-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.content-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.accordion-wrapper {
  width: 460px;
  margin-top: 32px;
}

.accordion-wrapper.faq {
  width: 100%;
}

.help-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.accordion-heading {
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 31px;
}

.faq-down-arrow {
  width: 20px;
}

.help-text-wrap.faq {
  display: none;
  max-width: 680px;
  margin-top: 24px;
}

.help-text {
  margin-bottom: 24px;
  color: #9bb1c8;
  font-size: 16px;
  line-height: 1.3em;
}

.gray-line {
  height: 2px;
  margin-bottom: 20px;
  background-color: #dadada;
}

.gray-line.faq {
  height: 1px;
}

.help-content {
  width: 100%;
  padding: 24px 24px 0px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 87.1%, 0.75);
  border-radius: 3px;
}

.help-h4 {
  color: #030f1c;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.help-h4.cont__h4--authors {
  color: salmon;
}

.expert-info-wrap {
  position: static;
  left: 0px;
  top: 140px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 121px;
  height: 121px;
  margin-right: 44px;
  margin-left: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.services-wrap .expert-info-wrap {
  margin-bottom: 40px;
  position: relative;
}
.services-wrap .circles-wrap--home .col__cont-wrap.col__cont-wrap--relative {
  flex-wrap: wrap;
  height: auto;
}

.services-wrap .expert-info-wrap .expert-desc {
  margin-top: 120px;
}

.services-wrap .expert-info-wrap--home {
  top: 0;
}

.expert-info-wrap.expert-info-wrap--2 {
  left: auto;
  top: 80px;
  right: 0%;
  bottom: auto;
}

.expert-info-wrap.expert-info-wrap--2.signup {
  left: auto;
  top: 0px;
  right: 50px;
  bottom: 0px;
}

.expert-info-wrap.expert-info-wrap--3 {
  left: 100px;
  top: 0%;
  right: auto;
  bottom: auto;
}

.expert-info-wrap.expert-info-wrap--3.signup {
  left: auto;
  top: auto;
  right: 170px;
  bottom: -20px;
}

.expert-info-wrap.expert-info-wrap--4 {
  left: -50px;
  top: auto;
  right: auto;
  bottom: 0px;
}

.expert-info-wrap.expert-info-wrap--4.signup {
  top: 60px;
}

.expert-info-wrap.expert-info-wrap--5 {
  left: 150px;
  top: auto;
  right: auto;
  bottom: -100px;
}

.expert-info-wrap.expert-info-wrap--6 {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -80px;
}

.expert-info-wrap.expert-info-wrap--7 {
  left: auto;
  top: 0%;
  right: 50px;
  bottom: auto;
}

.expert-info-wrap.expert-info-wrap--8 {
  left: auto;
  top: 100px;
  right: 170px;
  bottom: auto;
}

.expert-info-wrap.expert-info-wrap--8.signup {
  top: 0px;
}

.expert-info-wrap.expert-info-wrap--9 {
  left: 0px;
  top: auto;
  right: auto;
  bottom: -90px;
}

.expert-info-wrap.expert-info-wrap--9.signup {
  left: 130px;
  bottom: -20px;
}

.expert-info-wrap.expert-info-wrap--10 {
  left: 0%;
  top: 15px;
  right: auto;
  bottom: auto;
}

.expert-info-wrap.expert-info-wrap--10.signup {
  left: 0px;
  top: 50px;
  right: 0px;
}

.expert-info-wrap.expert-info-wrap--5 {
  left: -50px;
  top: auto;
  right: auto;
  bottom: 0px;
}

.expert-info-wrap.expert-info-wrap--5.signup {
  left: 100px;
  top: 0px;
}

.faq-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 38px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.back-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.back-link.back-link--arrow {
  position: absolute;
  left: 12px;
  top: -30px;
  right: auto;
  bottom: auto;
}

.back-link.payment {
  margin-bottom: 24px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.left-arrow-img {
  width: 20px;
}

.faq__h3.h3--white {
  color: #fff;
}

.faq__h3.h3--white.h3--small {
  font-size: 21px;
}

.faq__h3.h3--bold {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: center;
}

.faq__h3.h3--bold.h3--blue {
  margin-bottom: 0px;
  color: #9bb1c8;
}

.cont__h4 {
  margin-right: 10px;
  color: #ec4d33;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.cont__h4:hover {
  color: #ec4d33;
}

.sign-up-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 1160px;
  margin-top: 74px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  border-radius: 6px;
}

.sec__h3.h3--white {
  color: #fff;
}

.sec__h3.h3--white.h3--small {
  font-size: 21px;
}

.sec__h3.h3--bold {
  font-weight: 700;
  text-align: center;
}

.sec__h3.h3--bold.h3--blue {
  margin-top: 68px;
  margin-bottom: 0px;
  color: #9bb1c8;
}

.sign-up-form {
  width: 100%;
  background-color: transparent;
}

.sign-up-form__btn {
  width: 100%;
  padding: 16px 32px;
  border-radius: 6px;
  background-color: #2707df;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  font-weight: 600;
  cursor: pointer;
}

.sign-up-form__btn:hover {
  background-color: rgba(39, 7, 223, 0.8);
}

.checkbox {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-color: #2707df;
  cursor: pointer;
}

.checkbox.w--redirected-checked {
  border-color: #2707df;
  background-color: #2707df;
  box-shadow: 0 0 3px 0 #2707df;
}

.checkbox-label {
  font-size: 11px;
  font-weight: 400;
}

.h6.h6--blue {
  color: #9bb1c8;
}

.h6.h6--green {
  margin-top: 12px;
  margin-right: 21px;
  color: #2707df;
  font-size: 13px;
}

.checkbox-field {
  margin-top: 24px;
  margin-bottom: 14px;
}

.checkbox-field._2 {
  margin-top: 0px;
}

.request-wrap {
  width: 100%;
  margin-top: 60px;
}

.textarea {
  max-width: 392px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #dedede;
  border-radius: 8px;
}

.textarea:focus {
  border-color: #2707df;
}

.request-btn-wrap {
  position: relative;
}

.request-btn {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
}

.request-btn.btn--search {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.request-btn.btn--grid {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: box-shadow 200ms ease-in-out;
  transition: box-shadow 200ms ease-in-out;
  font-size: 1.5vw;
}

.request-btn.btn--grid:hover {
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.3);
}

.request-cta {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #2707df;
  border-radius: 4px;
  cursor: pointer;
}

.request-cta.request-cta--small.request-cta--center {
  margin-top: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.request-cta.signup {
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
}

.text.text--small {
  color: #2707df;
  font-size: 12px;
}

.arrow-up-img {
  height: 13px;
}

.back-arrow-img {
  width: 20px;
}

.col__col {
  width: 300px;
}

.col__col._2 {
  width: 163px;
  max-width: none;
  margin-left: 90px;
}

.calendar-wrap {
  padding-right: 4px;
  padding-left: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.calendar-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.right-link {
  padding: 12px;
  cursor: pointer;
}

.left-link {
  padding: 12px;
  cursor: pointer;
}

.calendar-arrow {
  height: 11px;
}

.calendar-arrow.left {
  opacity: 0.25;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.calendar-arrow.right {
  opacity: 0.25;
}

.calendar-days {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.c-day {
  padding: 10px;
}

.c-text {
  position: relative;
  z-index: 1;
  color: #999;
  font-size: 11px;
}

.c-text.c-text--black {
  color: #030f1c;
}

.calendar-dates {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 4px;
  padding-left: 6px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.c-date {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 14.28%;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.c-date.small {
  padding-right: 4px;
  padding-left: 4px;
}

.c-date.big {
  padding-right: 11px;
  padding-left: 12px;
}

.c-date.c-date--opacity {
  opacity: 0;
}

.green-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 55%;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 6px;
  background-color: #efefef;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
}

.green-bg.calendar {
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
  background-color: #2707df;
}

.col__text {
  margin-bottom: 35px;
  font-size: 15px;
}

.col__text.col__text--green {
  margin-top: 20px;
  margin-bottom: 0px;
  color: #2707df;
  text-align: center;
}

.col__text.col__text--green.col__text--opacity {
  margin-top: 0px;
  opacity: 0;
}

.textarea-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 128px;
  margin-bottom: 35px;
}

.sign-up1-form {
  width: 100%;
  background-color: transparent;
}

.payment-wrap {
  width: 100%;
}

.payment__h3.h3--white {
  color: #fff;
}

.payment__h3.h3--white.h3--small {
  font-size: 21px;
}

.payment__h3.h3--bold {
  margin-bottom: 34px;
  font-weight: 700;
}

.payment__h3.h3--bold.h3--blue {
  margin-top: 68px;
  margin-bottom: 0px;
  color: #9bb1c8;
}

.payment__h3.h3--bold.h3--left {
  margin-bottom: 28px;
  text-align: left;
}

.payment__h3.h3--green {
  color: #2707df;
}

.sign-up3-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.arrow-down-img {
  height: 13px;
  margin-right: 16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.tab__content-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.tab__help-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tab__title {
  margin-bottom: 24px;
}

.tab__title._2 {
  margin-top: 50px;
  margin-bottom: 8px;
}

.meet-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 496px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 87.1%, 0.75);
  border-radius: 3px;
}

.meet-box.meet-box--margin {
  margin-top: 40px;
}

.date-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
  height: 79px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: hsla(0, 0%, 87.1%, 0.25);
}

.text-block-2 {
  font-size: 15px;
}

.date-title {
  font-size: 14px;
  font-weight: 700;
}

.date-title.date-title--thin {
  margin-top: 4px;
  font-weight: 400;
}

.meet-desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.meet-h4 {
  margin-top: 8px;
  color: #030f1c;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.meet-h4.cont__h4--authors {
  color: salmon;
}

.tab__circles-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tab__circle-box {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.tab__circle-box.tab__circle-box--2 {
  margin-left: -10px;
}

.tab__circle-box.tab__circle-box--3 {
  margin-left: -10px;
}

.tab__circle-box.tab__circle-box--4 {
  margin-left: -10px;
}

.tab__help-content {
  width: 496px;
  padding: 24px 24px 0px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 87.1%, 0.75);
  border-radius: 3px;
}

.subtitle-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.meet-box__first-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.about-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55%;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.brand-logo {
  margin-top: 26px;
  margin-bottom: 56px;
}

.about__h3 {
  margin-top: 50px;
}

.about__h3.h3--white {
  color: #fff;
}

.about__h3.h3--white.h3--small {
  font-size: 20px;
  line-height: 1.1em;
}

.about__h3.h3--bold {
  margin-bottom: 96px;
  font-weight: 700;
  text-align: center;
}

.about__h3.h3--bold.h3--blue {
  margin-top: 68px;
  margin-bottom: 0px;
  color: #9bb1c8;
}

.about__h3.h3--bold.h3--left {
  margin-bottom: 28px;
  text-align: left;
}

.about__h3.h3--green {
  color: #2707df;
}

.expert-content {
  width: 100%;
  padding: 24px 24px 0px;
}

.stars-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 110px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.stars {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.star-img {
  margin-right: 5px;
  margin-left: 5px;
}

.stars-title {
  margin-top: 20px;
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
}

.bg-green {
  width: 100%;
  background-color: $primary-color;
}

.nav--green {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  width: 100%;
  margin-top: 124px;
}

.white-line {
  position: absolute;
  left: auto;
  top: 0px;
  right: 0%;
  bottom: 0px;
  width: 2px;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 1px;
  background-color: #fff;
}

.white-line.white-line--horizontal {
  position: static;
  width: 0%;
  height: 2px;
  margin-top: 4px;
  margin-bottom: 0px;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.footer-link {
  padding: 10px 22px;
  border: 1px solid #414747;
  border-radius: 40px;
  transition: color .3s ease, background-color .3s ease;
}

.footer-link:hover {
  background-color: #414747;
}

.footer-link.footer-link--mr {
  margin-right: 24px;
  padding: 10px 22px;
  border-style: solid;
  border-width: 1px;
  border-color: #414747;
  border-radius: 40px;
}

.link-text {
  color: #414747;
  font-size: 16px;
}

.footer__link-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}

.footer__link-wrap--2 {
  flex-wrap: wrap;
}

.footer__link-wrap.footer__link-wrap--right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer__social-link {
  margin-right: 12px;
  margin-left: 12px;
  cursor: pointer;
}

.s-link-img.s-link-img--big {
  width: 33px;
}

.field-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form__img-t-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form__text {
  color: rgba(51, 51, 51, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.delete-img {
  width: 32px;
  border-style: none;
  border-width: 1px;
  border-color: #727c88;
  opacity: 0.3;
}

.delete-link {
  cursor: pointer;
}

.btn-rounded {
  display: inline-block;
  padding: 15px 60px;
  border-radius: 90px;
  background-color: $primary-color;
  font-weight: 600;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar {
  position: absolute;
}

.cookie-cont {
  width: 100%;
}

.list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  margin-right: 16px;
  border-radius: 50%;
  background-color: #030f1c;
}

.link--green {
  color: #2707df;
  cursor: pointer;
}

.table {
  margin-top: 32px;
}

.table__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: -12px;
  padding: 12px;
  background-color: rgba(155, 177, 200, 0.1);
}

.table__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  margin-right: -12px;
  margin-left: -12px;
  padding: 12px;
}

.table__row.table__row--bg {
  background-color: rgba(155, 177, 200, 0.1);
}

.table__row.table__row--bg.table__row--mt {
  margin-top: 0px;
}

.table__row.table__row--mt {
  margin-top: 0px;
}

.table__cont-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.lottie-animation {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

.hero__search-input {
  height: 100%;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  border: 1px solid #d7dfe5;
  border-radius: 80px;
  -webkit-transition: box-shadow 300ms ease-in-out, border-color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, border-color 300ms ease-in-out;
}

.hero__search-input:hover {
  border-style: solid;
  border-color: #3ca545;
}

.hero__search-input:active {
  box-shadow: 0 0 0 4px rgba(62, 164, 71, 0.4);
}

.hero__search-input:focus {
  box-shadow: 0 0 0 4px rgba(62, 164, 71, 0.4);
}

.hero__search-input::-webkit-input-placeholder {
  color: #9bb1c8;
}

.hero__search-input:-ms-input-placeholder {
  color: #9bb1c8;
}

.hero__search-input::-ms-input-placeholder {
  color: #9bb1c8;
}

.hero__search-input::placeholder {
  color: #9bb1c8;
}

.feature-gif {
  width: 85px;
  height: 85px;
  margin-bottom: 20px;
}

.lottie {
  overflow: hidden;
  min-height: 450px;
}

.nav-dropdown__list.w--open {
  left: 0%;
  top: 80px;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 21px;
  padding: 48px;
  border-top: 1px solid #2707df;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(56, 96, 165, 0.15);
}

.dropdown-toggle {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-dropdown__link-title {
  color: $primary-color;
  font-weight: 600;
}

.nav-dropdown__link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 31%;
  padding: 48px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #2707df;
  cursor: pointer;
}

.nav-dropdown__desc {
  max-width: 280px;
  margin-top: 8px;
  color: #b7b7b7;
  font-size: 16px;
  text-align: left;
}

.v-gray-line {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 2px;
  height: 70%;
  margin-top: auto;
  margin-bottom: auto;
  background-color: hsla(0, 0%, 71.8%, 0.5);
}

.nav__dropdown-close-btn {
  position: absolute;
  left: auto;
  top: 12px;
  right: 12px;
  bottom: auto;
  z-index: 1001;
  cursor: pointer;
}

.nav-dropdown__img {
  width: 80px;
  margin-right: 12px;
  cursor: pointer;
}

.nav-dropdown__gif {
  width: 100px;
  margin-right: 24px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.nav__dropdown-wrap {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
}

.nav__dropdown-wrap.nav__link {
  margin-left: 24px;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__dropdown-wrap.hidden {
  display: none;
}

.nav__dropdown-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__dropdown-img {
  position: absolute;
  left: auto;
  top: 0%;
  right: 20px;
  bottom: 0%;
  width: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.nav__dropdown-content {
  position: absolute;
  left: 0%;
  top: 89px;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #2707df;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 30px -10px rgba(56, 96, 165, 0.15);
}

.dropdown-toggle-2 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.bold {
  font-weight: bold;
}

.dropdown-toggle-3 {
  padding-top: 50px;
  padding-bottom: 12px;
  color: #2707df;
  font-size: 12px;
}

.dropdown-toggle-2.nav__link {
  padding-right: 40px;
}

.col-formule__title {
  margin-top: 16px;
  color: #9bb1c8;
  font-weight: 600;
}

.div-block {
  display: block;
}

.legalcopy {
  margin-top: 10px;
  padding-top: 0px;
  color: #b7b7b7;
  font-size: 11px;
  line-height: 1.2em;
  padding-bottom: 20px;
}

.cookie-wrap {
  position: relative;
  z-index: 1;
  overflow: auto;
  width: 100%;
  max-width: 680px;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  border-radius: 8px;
  background-color: #fff;
}

.cookie__h {
  font-weight: 500;
  text-align: center;
}

.cookie__desc {
  margin-top: 24px;
  font-size: 18px;
  text-align: left;
}

.cookie__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cookie__field.mt {
  margin-top: 32px;
}

.cookie__text {
  font-size: 16px;
  font-weight: 600;
}

.switch {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  padding: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #030f1c;
  border-radius: 50px;
  background-color: #efefef;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.input-inline-block {
  max-width: 100%;
  display: inline-block;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 0px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-style: solid;
  border-width: 1px;
  border-color: #030f1c;
  border-radius: 50%;
  background-color: #fff;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input_toogle:checked+.slider {
  background-color: rgb(59, 181, 74);
}

.input_toogle:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.input_toogle:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.configure {
  margin-top: 24px;
  padding-right: 0px;
  padding-left: 40px;
}

.cookie__text {
  font-size: 16px;
  font-weight: 600;
  width: 70%;
}

.cookie__text.cookie__text--light {
  color: #9f9f9f;
  font-weight: 500;
}

.configure-body {
  overflow: hidden;
}

.configure__desc {
  max-width: 420px;
  font-size: 14px;
}

.cookie__btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cookie-button {
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.cookie__btn {
  padding: 15px 60px;
  border-radius: 90px;
  background-color: $primary-color;
  font-weight: 600;
  text-align: center;
}

.link {
  text-decoration: underline;
}

.configure__desc.mt {
  margin-top: 32px;
}

.cookie-title:hover {
  cursor: pointer;
}


@media screen and (min-width: 1440px) {
  .wrapper {
    max-width: 100%;
  }

  .nav {
    padding-right: 108px;
    padding-left: 108px;
  }

  .nav.nav--profile {
    padding-right: 108px;
    padding-left: 108px;
  }

  .brand {
    left: 0px;
  }

  .brand.w--current {
    left: 0px;
  }

  .container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .container.container--nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 24px;
  }

  .container.container--footer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .container.container--hero {
    min-height: 70vh;
    padding-right: 0px;
    padding-left: 0px;
  }

  .container.container--nav-green {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 68px;
    padding-right: 0px;
    padding-left: 0px;
    border-bottom: 1px solid transparent;
  }

  .nav-links-wrap {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .nav-links-wrap.nav-links-wrap--mid {
    margin-left: 15%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .nav-links-wrap.nav-links-wrap--green {
    /* width: 40%; */
    /* -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; */
  }

  .nav-links-wrap.nav-links-wrap--green2 {
    margin-left: 15%;
  }

  .nav__link {
    margin-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 50px;
    -webkit-transition: background-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;
  }

  .nav__link:hover {
    background-color: #efefef;
  }

  .nav__link.w--current {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 50px;
    background-color: $primary-color;
    color: #fff;
  }

  .nav__link.nav__link--contact {
    background-color: $primary-color;
  }

  .nav__link.nav__link--dropdown {
    margin-left: 18px;
    padding-right: 32px;
  }

  .nav__link.nav__link--dropdown.w--open {
    padding-right: 32px;
  }

  .nav__link.nav__link--white.w--current {
    padding-left: 17px;
  }

  .nav__link.nav__link--mr {
    margin-right: 24px;
    margin-left: 0px;
  }

  .nav__menu {
    position: static;
  }

  .sec {
    padding-right: 108px;
    padding-left: 108px;
  }

  .sec.sec--footer {
    padding-right: 108px;
    padding-left: 108px;
    border-top-style: none;
    background-color: #f2f2f2;
  }

  .hero-title {
    text-align: center;
  }

  .gray-bg {
    display: none;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .btn.btn--grid {
    font-size: 14px;
  }

  .grid {
    grid-auto-flow: row;
  }

  .grid.grid--step1 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-columns: 288px 288px 288px 288px;
    grid-template-columns: 288px 288px 288px 288px;
    -ms-grid-rows: 250px 250px;
    grid-template-rows: 250px 250px;
  }

  .h3.h3--white.h3--small {
    font-size: 20px;
  }

  .grid__desc.step1 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 16px;
  }

  .row {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .row.row--stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .row.row--hidden {
    display: none;
  }

  .col.col--sign-up {
    padding: 160px 72px;
  }

  .col.col--sign-up.col--sign-up--white {
    background-color: #fff;
  }

  .col.footer__col {
    width: 70%;
  }

  .col.col__formules {
    margin-left: 0px;
  }

  .h4.h4--blue {
    margin-bottom: 40px;
  }

  .circles-wrap.signup {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .partners-wrap {
    padding-bottom: 20px;
  }

  .footer__logo.footer__logo--opacity {
    opacity: 0;
  }

  .expert-desc {
    display: none;
  }

  .icon {
    margin-right: 24px;
  }

  .text-block {
    margin-right: 8px;
  }

  .dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: -18px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .dropdown__list.w--open {
    margin-top: 54px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(56, 96, 165, 0.15);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .dropdown__link {
    color: #2707df;
  }

  .form.request-detail {
    margin-top: 46px;
  }

  .form__text-field:focus {
    border-style: none none solid;
    border-color: #2707df;
  }

  .faq__h3.h3--white.h3--small {
    font-size: 21px;
  }

  .sec__h3.h3--white.h3--small {
    font-size: 21px;
  }

  .request-wrap {
    margin-top: 100px;
  }

  .request-form {
    margin-bottom: 0px;
  }

  .textarea:focus {
    border-color: #2707df;
  }

  .request-btn.btn--grid {
    font-size: 15px;
  }

  .payment__h3.h3--white.h3--small {
    font-size: 21px;
  }

  .tab__help-wrap {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .tab__title {
    margin-bottom: 24px;
  }

  .tab__title._2 {
    margin-bottom: 8px;
  }

  .meet-box {
    width: 496px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .meet-box__first-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .about__h3.h3--white.h3--small {
    font-size: 20px;
  }

  .bg-green {
    padding-right: 108px;
    padding-left: 108px;
    border-bottom: 1px solid transparent;
    background-color: $primary-color;
  }

  .nav--green {
    margin-top: 124px;
  }

  .footer-link {
    padding: 10px 22px;
    border: 1px solid #414747;
    border-radius: 40px;
    -webkit-transition: background-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;
    cursor: pointer;
  }

  .footer-link:hover {
    background-color: #414747;
  }

  .footer-link.footer-link--mr {
    margin-right: 24px;
  }

  .link-text {
    color: #414747;
    font-size: 16px;
    font-weight: 600;
  }

  .footer__link-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
  }

  .footer__link-wrap.footer__link-wrap--right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .footer__social-link {
    margin-right: 12px;
    margin-left: 12px;
    cursor: pointer;
  }

  .s-link-img.s-link-img--big {
    width: 33px;
  }

  .btn-rounded {
    font-size: 18px;
    font-weight: 500;
    white-space: normal;
  }

  .btn-rounded:hover {
    background-color: rgba(39, 7, 223, 0.8);
  }

  .section {
    margin-top: 60px;
  }

  .lottie-animation {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }

  .lottie {
    min-height: 500px;
  }

  .nav-dropdown__list.w--open {
    top: 80px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(56, 96, 165, 0.15);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .nav-dropdown__link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #2707df;
  }

  .nav__dropdown-wrap.nav__link {
    margin-left: 12px;
  }

  .nav__dropdown-content {
    top: 101px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .container.container--nav {
    height: 60px;
    padding-right: 6px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .container.container--footer._2 {
    padding-top: 56px;
  }

  .container.container--hero.left-align {
    min-height: 100%;
  }

  .container.container--nav-green {
    height: 60px;
  }

  .nav-links-wrap {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }

  .nav-links-wrap.nav-links-wrap--mid {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .nav-links-wrap.nav-links-wrap--green {
    position: static;
    width: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: $primary-color;
  }

  .nav-links-wrap.nav-links-wrap--green2 {
    position: static;
    width: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: $primary-color;
  }

  .nav__link {
    margin-right: 0px;
    margin-bottom: 12px;
  }

  .nav__link.nav__link--contact {
    margin-bottom: 0px;
    margin-left: 0px;
    background-color: transparent;
  }

  .nav__link.nav__link--margin {
    margin-right: 0px;
    margin-left: 0px;
  }

  .nav__link.nav__link--dropdown {
    margin-left: 0px;
  }

  .nav__link.nav__link--white {
    margin-bottom: 0px;
    color: #fff;
  }

  .nav__link.nav__link--white:hover {
    background-color: #2707df;
  }

  .nav__link.nav__link--white.w--current {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .nav__link.nav__link--white.nav__link--mr {
    margin-bottom: 0px;
  }

  .nav__link.nav__link--mr {
    margin-right: 0px;
    margin-bottom: 12px;
  }

  .nav__menu {
    position: absolute;
    height: 100vh;
    padding-top: 72px;
    padding-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
  }

  .nav__menu.nav__menu--green {
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }

  .hero-title {
    text-align: center;
  }

  .hero-subtitle.left-align {
    text-align: center;
  }

  .h2 {
    text-align: center;
  }

  .h2.h2--left {
    text-align: left;
  }

  .grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: minmax(360px, 1fr) minmax(360px, 1fr) minmax(360px, 1fr);
    grid-template-rows: minmax(360px, 1fr) minmax(360px, 1fr) minmax(360px, 1fr);
  }

  .grid.grid--d-hidden {
    display: none;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: minmax(360px, 1fr) minmax(360px, 1fr) minmax(360px, 1fr);
    grid-template-rows: minmax(360px, 1fr) minmax(360px, 1fr) minmax(360px, 1fr);
  }

  .h3.h3--white {
    font-size: 24px;
  }
  
  .h3.h3--white span {
    white-space: normal !important;
  }

  .grid__desc.step1 {
    font-size: 17px;
  }

  .text-bg {
    background-color: transparent;
  }

  .features-wrap {
    // padding-top: 96px;
  }

  .row {
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .col {
    width: 100%;
  }

  .col.col--short {
    width: 100%;
  }

  .col.col--short1.col--footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .col.col--short2.col--footer {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .col.col--short3.col--footer {
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
  }

  .col.m-hidden {
    display: none;
  }

  .col.col--request {
    width: 100%;
  }

  .col.col--request-long {
    width: 100%;
  }

  .col.footer__col {
    width: 100%;
  }

  .col.table__col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }

  .col.hero-col {
    width: 100%;
  }

  .col.hero-col-sm {
    width: 100%;
  }

  .col.col__formules {
    width: 100%;
  }

  .col__cont-wrap.col__cont-wrap--vertical._2 {
    margin-top: 20px;
  }

  .col__cont-wrap.col__cont-wrap--vertical._3 {
    margin-top: 20px;
  }

  .col__cont-wrap.col__cont-wrap--relative {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }

  .h4.h4--table {
    display: block;
    width: 25%;
  }

  .p.p--left.p--short {
    width: 75%;
  }

  .expert-box.circle-box--5 {
    left: 0%;
    top: 80px;
    right: auto;
    bottom: 0%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .expert-box.circle-box--6 {
    left: 150px;
  }

  .expert-box.circle-box--8 {
    right: 50px;
  }

  .expert-box.circle-box--9 {
    top: -50px;
  }

  .expert-box.m-hidden {
    left: 100px;
    top: -50px;
    right: auto;
    bottom: auto;
  }

  .expert-box.d-hidden {
    left: 100px;
    top: -50px;
    right: auto;
    bottom: auto;
    display: block;
  }

  .expert-box.circle-box--10 {
    right: 50px;
  }

  .formules-wrap {
    padding-top: 96px;
  }

  .testimonials-wrap {
    padding-top: 96px;
  }

  .partners-wrap {
    padding-bottom: 96px;
  }

  .footer__link {
    margin-top: 16px;
    /* margin-right: 0px; */
  }

  .footer__cont {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer__cont.footer__cont--right {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .social-links__wrap {
    position: static;
    margin-top: 16px;
  }

  .nav__link2.nav__link--contact {
    margin-top: 16px;
    margin-left: 0px;
  }

  .menu-button {
    background-color: transparent;
  }

  .menu-button.w--open {
    background-color: #fff;
  }

  .close-btn {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
  }

  .d-cont-wrap {
    padding-right: 24px;
    padding-left: 24px;
  }

  .form__row {
    width: 100%;
  }

  .c-form__btn {
    display: none;
  }

  .c-form__btn.btn--full {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .c-btn {
    display: none;
  }

  .c-btn.btn--red {
    display: block;
    margin-top: 20px;
  }

  .form__field-wrap.form__field-wrap--right {
    margin-left: 20px;
  }

  .form__field-wrap.form__field-wrap--left {
    margin-right: 20px;
    margin-left: 0px;
  }

  .tab-wrap {
    padding-top: 96px;
  }

  .tabs__menu {
    width: 33%;
  }

  .tabs__content {
    width: 67%;
    padding-left: 24px;
  }

  .breadcrumbs-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .help-wrap {
    width: 70%;
  }

  .help-content {
    max-width: 500px;
  }

  .expert-info-wrap.expert-info-wrap--6 {
    right: 50px;
  }

  .expert-info-wrap.expert-info-wrap--7 {
    right: 100px;
  }

  .expert-info-wrap.expert-info-wrap--8 {
    right: 270px;
  }

  .expert-info-wrap.expert-info-wrap--8.signup {
    right: 170px;
  }

  .expert-info-wrap.expert-info-wrap--9 {
    left: 50px;
  }

  .expert-info-wrap.expert-info-wrap--10 {
    left: 100px;
  }

  .sign-up-wrap {
    max-width: 580px;
  }

  .payment-wrap {
    padding-top: 96px;
  }

  .tab__help-wrap {
    width: 70%;
  }

  .meet-box {
    width: 450px;
  }

  .meet-box.meet-box--margin {
    width: 450px;
  }

  .date-box {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .date-title {
    font-size: 13px;
  }

  .tab__help-content {
    width: 396px;
  }

  .about-wrap {
    width: 70%;
  }

  .stars-box {
    margin-top: 56px;
  }

  .footer__link-wrap.footer__link-wrap--right {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .table__title {
    display: none;
  }

  .table__title.table__title--inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: transparent;
  }

  .table__row {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  .table__cont-wrap {
    display: block;
  }

  .lottie-animation {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }

  .lottie {
    width: 60%;
    margin-right: auto;
    margin-bottom: 48px;
    margin-left: auto;
  }

  .nav-dropdown__list.w--open {
    position: static;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .nav-dropdown__link-title {
    text-align: left;
  }

  .nav-dropdown__link {
    width: 33%;
    padding-right: 12px;
    padding-left: 12px;
  }

  .v-gray-line {
    margin-right: auto;
    margin-left: auto;
  }

  .nav__dropdown-close-btn {
    display: none;
  }

  .nav-dropdown__img {
    width: 60px;
  }

  .nav-dropdown__gif {
    margin-right: 12px;
  }

  .nav__dropdown-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav__dropdown-wrap.nav__link {
    margin-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .nav__dropdown-content {
    position: static;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top-style: none;
  }

  .dropdown-toggle-2.nav__link {
    margin-left: 0px;
  }

  .col-formule__title {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .brand {
    padding-left: 0px;
  }

  .container.container--nav-green {
    height: auto;
  }

  .nav-links-wrap.nav-links-wrap--green {
    /* width: 60%; */
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav-links-wrap.nav-links-wrap--green2 {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .nav__link.nav__link--contact {
    background-color: transparent;
  }

  .nav__link.nav__link--contact.mr0:hover {
    background-color: transparent;
  }

  .nav__link.nav__link--white.w--current {
    top: 0px;
    margin-right: 0px;
    background-color: transparent;
    opacity: 1;
  }

  .nav__link.nav__link--white.nav__link---position {
    left: 24px;
    top: 0%;
    right: auto;
    bottom: auto;
  }

  .nav__menu {
    z-index: 1000;
  }

  .nav__menu.nav__menu--green {
    flex-wrap: wrap;
    justify-content: center;
    /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; */
  }

  .nav__link {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    line-height: 16px !important;
  }

  .sec {
    padding-top: 58px;
    padding-bottom: 58px;
  }

  .sec.sec--step2 {
    padding-top: 104px;
  }

  .hero-title {
    max-width: 351px;
    margin-right: auto;
    margin-left: auto;
    font-size: 28px;
  }

  .hero-subtitle {
    max-width: 345px;
  }

  .hero-subtitle.left-align {
    margin-right: auto;
    margin-left: auto;
  }

  .search-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 96px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .search-box {
    width: 100%;
    margin-bottom: 30px;
  }

  .search-input {
    width: 100%;
  }

  .search__btn-wrap {
    position: relative;
    right: 0px;
    width: 100%;
    height: 56px;
    margin-top: 10px;
  }

  .btn.btn--search {
    height: 56px;
  }

  .btn.btn--grid {
    font-size: 15px;
  }

  .btn.btn--grid.btn--small {
    font-size: 12px;
  }

  .h2 {
    font-size: 24px;
  }

  .grid {
    display: none;
  }

  .grid.grid--d-hidden {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: minmax(360px, 1fr) minmax(360px, 1fr) minmax(360px, 1fr);
    grid-template-rows: minmax(360px, 1fr) minmax(360px, 1fr) minmax(360px, 1fr);
  }

  .h3.h3--white {
    margin-top: 0px;
    font-size: 17px;
  }

  .h3.h3--bold {
    margin-bottom: 46px;
    font-size: 24px;
  }

  .grid__desc {
    font-size: 15px;
  }

  .text-bg {
    background-color: transparent;
  }

  .features-wrap {
    padding-bottom: 46px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .col.col--short {
    width: 100%;
  }

  .col.col--sign-up {
    max-width: 330px;
    padding: 80px 36px;
  }

  .col.col--sign-up.col--sign-up--white {
    padding: 80px 36px;
  }

  .col.col--request {
    margin-top: 24px;
    padding-right: 12px;
    border-right-style: none;
  }

  .col.col--request-long {
    padding-left: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .col__cont-wrap.col__cont-wrap--relative {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-width: 800px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; */
  }

  .col__cont-wrap.col__cont-wrap--relative.signup {
    margin-bottom: 124px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .h4.h4--blue {
    margin-bottom: 46px;
  }

  .h4.h4--green {
    max-width: 320px;
  }

  .h4.h4--green.h4--centered {
    max-width: 100%;
  }

  .h4.h4--table {
    width: 30%;
  }

  .p.p--left.p--short {
    width: 70%;
  }

  .services-wrap {
    padding-bottom: 0px;
  }

  .circles-wrap.signup {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .expert-box.circle-box--2.signup {
    width: 50px;
    height: 50px;
  }

  .expert-box.circle-box--3.signup {
    width: 50px;
    height: 50px;
  }

  .expert-box.circle-box--5 {
    top: 30px;
    width: 66px;
    height: 66px;
  }

  .expert-box.circle-box--6 {
    bottom: 20px;
    width: 66px;
    height: 66px;
  }

  .expert-box.circle-box--7 {
    right: 70px;
    width: 66px;
    height: 66px;
  }

  .expert-box.circle-box--8 {
    bottom: 30px;
    width: 66px;
    height: 66px;
  }

  .expert-box.circle-box--8.signup {
    width: 50px;
    height: 50px;
  }

  .expert-box.circle-box--9 {
    width: 66px;
    height: 66px;
  }

  .expert-box.circle-box--9.signup {
    width: 50px;
    height: 50px;
  }

  .expert-box.m-hidden {
    top: 0px;
    width: 66px;
    height: 66px;
  }

  .expert-box.circle-box--10 {
    position: static;
    bottom: 30px;
    display: block;
    width: 66px;
    height: 66px;
  }

  .expert-box.signup {
    width: 50px;
    height: 50px;
  }

  .formules-wrap {
    padding-top: 46px;
  }

  .h1.h1--center {
    font-size: 28px;
  }

  .link-box.link-box--sign-up.link-box--green {
    margin-top: 26px;
    font-size: 15px;
  }

  .testimonials-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .arrow-box {
    width: 25px;
    height: 25px;
  }

  .slider__arrow-img {
    width: 15px;
  }

  .slider__arrow-img.slider__arrow-img--left {
    width: 15px;
  }

  .slider__arrow-wrap {
    width: 25px;
    height: 25px;
  }

  .slider__arrow-wrap.slider__arrow-wrap--left {
    width: 25px;
    height: 25px;
  }

  .partner-logo {
    max-width: 105px;
  }

  .expert-desc.signup {
    margin-left: 30px;
  }

  .expert-desc.signup._3 {
    margin-left: -30px;
  }

  .d-cont-wrap {
    width: 100%;
    margin-top: 50px;
  }

  .d-cont__p {
    font-size: 16px;
  }

  .experts-h4 {
    font-size: 12px;
  }

  .a-hero__text {
    font-size: 14px;
  }

  .experts__p {
    width: 100%;
  }

  .form__row {
    width: 100%;
  }

  .form {
    max-width: 100%;
  }

  .c-form__wrap2 {
    width: 100%;
    margin-right: 0px;
  }

  .form__btn-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .form__field-wrap {
    width: 100%;
    margin-right: 0px;
  }

  .form__field-wrap.form__field-wrap--ml {
    margin-left: 0px;
  }

  .form__field-wrap.width {
    width: 100%;
  }

  .form__field-wrap.form__field-wrap--sm {
    width: 100%;
    margin-left: 0px;
  }

  .form__text-field {
    width: 100%;
  }

  .tab-wrap {
    padding-top: 44px;
    padding-bottom: 96px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tabs {
    margin-top: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tabs__menu {
    display: -ms-grid;
    display: grid;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: minmax(166px, 1fr) minmax(166px, 1fr);
    grid-template-columns: minmax(166px, 1fr) minmax(166px, 1fr);
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .tabs__link {
    width: 100%;
    max-width: none;
    border-style: solid;
    border-width: 1px;
    border-color: #2707df;
    border-radius: 3px;
    text-align: center;
  }

  .tabs__link.w--current {
    max-width: none;
  }

  .tabs__content {
    width: 100%;
    padding-top: 36px;
    padding-left: 0px;
  }

  .form__btn {
    margin-top: 0px;
  }

  .form__btn.form__btn--green {
    margin-bottom: 16px;
  }

  .breadcrumbs-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .help-wrap {
    width: 100%;
  }

  .accordion-wrapper {
    width: 100%;
  }

  .accordion-heading {
    font-size: 18px;
    line-height: 25px;
  }

  .help-text {
    font-size: 18px;
  }

  .help-content {
    width: 100%;
  }

  .help-h4 {
    font-size: 12px;
  }

  .expert-info-wrap {
    margin: 44px 40px 88px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .expert-info-wrap.expert-info-wrap--3.signup {
    right: 120px;
  }

  .expert-info-wrap.expert-info-wrap--6 {
    right: 100px;
  }

  .expert-info-wrap.expert-info-wrap--7 {
    top: 15px;
  }

  .expert-info-wrap.expert-info-wrap--8 {
    right: 220px;
  }

  .expert-info-wrap.expert-info-wrap--8.signup {
    right: 120px;
  }

  .expert-info-wrap.expert-info-wrap--9 {
    left: 100px;
  }

  .expert-info-wrap.expert-info-wrap--9.signup {
    left: 80px;
  }

  .expert-info-wrap.expert-info-wrap--10 {
    left: 50px;
    top: 0px;
  }

  .expert-info-wrap.expert-info-wrap--5.signup {
    left: 50px;
  }

  .expert-info-wrap.signup {
    margin-top: 24px;
    margin-bottom: 0px;
  }

  .faq__h3.h3--white {
    font-size: 17px;
  }

  .faq__h3.h3--bold {
    margin-right: auto;
    margin-bottom: 46px;
    margin-left: auto;
    font-size: 24px;
  }

  .cont__h4 {
    font-size: 12px;
  }

  .sign-up-wrap {
    max-width: 330px;
    border-style: none;
  }

  .sec__h3.h3--white {
    font-size: 17px;
  }

  .sec__h3.h3--bold {
    font-size: 17px;
  }

  .sign-up-form__btn {
    margin-top: 0px;
  }

  .textarea {
    max-width: 100%;
  }

  .request-btn.btn--search {
    height: 56px;
  }

  .request-btn.btn--grid {
    font-size: 15px;
  }

  .col__col {
    width: 100%;
  }

  .col__col._2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 163px;
    margin-top: 25px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .col__text {
    text-align: center;
  }

  .payment-wrap {
    padding-top: 44px;
    padding-bottom: 96px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .payment__h3.h3--white {
    font-size: 17px;
  }

  .payment__h3.h3--bold {
    margin-bottom: 46px;
    font-size: 24px;
  }

  .sign-up3-wrap {
    padding-bottom: 0px;
  }

  .tab__help-wrap {
    width: 100%;
  }

  .meet-box {
    width: 100%;
  }

  .meet-box.meet-box--margin {
    width: 100%;
  }

  .meet-h4 {
    font-size: 12px;
  }

  .tab__help-content {
    width: 100%;
  }

  .about-wrap {
    width: 100%;
  }

  .about__h3 {
    font-size: 17px;
  }

  .about__h3.h3--white {
    font-size: 17px;
  }

  .about__h3.h3--bold {
    margin-bottom: 46px;
    font-size: 24px;
  }

  .expert-content {
    width: 100%;
  }

  .nav--green {
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: $primary-color;
  }

  .footer-link.footer-link--mr {
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .footer__link-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer__link-wrap.footer__link-wrap--right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .field-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero__search-input {
    width: 100%;
  }

  .lottie {
    min-height: 300px;
  }

  .nav-dropdown__link {
    width: 90%;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .v-gray-line {
    display: none;
  }

  .nav-dropdown__gif {
    width: 50px;
  }
}

@media screen and (max-width: 479px) {

  .cookie-wrap {
    padding: 30px;
  }

  .cookie__desc {
    font-size: 16px;
  }

  .cookie__text {
    font-size: 14px;
  }

  .configure__desc {
    font-size: 12px;
  }

  .configure {
    padding-left: unset;
  }

  .cookie__btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  .nav {
    padding-top: 12px;
  }

  .brand {
    left: 15px;
    padding-left: 0px;
  }

  .brand.w--current {
    left: 15px;
    padding-left: 0px;
  }

  .brand__img {
    max-width: 80%;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .container.container--nav {
    padding-right: 0px;
    padding-left: 0px;
  }

  .container.container--hero.payment {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .container.container--nav-green {
    padding-right: 0px;
    padding-left: 0px;
  }

  .nav-links-wrap.nav-links-wrap--green {
    width: 100%;
  }

  .nav-links-wrap.nav-links-wrap--green2 {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .nav__link.nav__link--white.w--current {
    position: relative;
    left: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .nav__link.nav__link--white.nav__link---position {
    position: static;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .sec {
    padding-top: 58px;
  }

  .search-wrap.step1 {
    margin-bottom: 32px;
  }

  .btn.btn--search {
    cursor: pointer;
  }

  .btn.btn--grid.btn--small {
    font-size: 14px;
  }

  .grid {
    -ms-grid-rows: minmax(420px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr);
    grid-template-rows: minmax(420px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr);
  }

  .grid.grid--step1 {
    margin-top: 0px;
    grid-row-gap: 24px;
    -ms-grid-rows: 280px 280px 280px 280px 280px;
    grid-template-rows: 280px 280px 280px 280px 280px;
  }

  .text-box {
    padding-right: 16px;
    padding-left: 16px;
  }

  .grid__desc.step1 {
    font-size: 15px;
  }

  .btn-wrap.btn-wrap--short {
    width: 100%;
  }

  .features-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .col.col--sign-up.col--sign-up--white {
    padding-right: 12px;
    padding-left: 12px;
  }

  .col.col--request {
    padding-right: 0px;
    padding-left: 0px;
  }

  .col.table__col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .col.col__formules {
    padding-right: 0px;
    padding-left: 0px;
  }

  /* .col__cont-wrap.col__cont-wrap--relative {
    height: 200px;
  } */
  .col__cont-wrap.col__cont-wrap--relative.signup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .h4.h4--green {
    max-width: 280px;
  }

  .h4.h4--table {
    width: 100%;
  }

  .p.col__p {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 15px;
    text-align: justify;
  }

  .p.p--left.p--short {
    width: 100%;
    margin-top: 16px;
  }

  .circles-wrap {
    margin-bottom: 0px;
  }

  .circles-wrap.signup {
    width: 100%;
    height: 121px;
    max-width: 304px;
    margin-top: 60px;
    margin-bottom: 120px;
  }

  .expert-box {
    /* width: 66px;
    height: 66px; */
  }

  .expert-box.circle-box--5 {
    left: -20px;
    top: 10px;
  }

  .expert-box.circle-box--6 {
    left: 50px;
  }

  .expert-box.circle-box--7 {
    right: 20px;
  }

  .expert-box.circle-box--8 {
    right: 50px;
    bottom: 40px;
  }

  .expert-box.circle-box--9 {
    left: 0px;
  }

  .expert-box.m-hidden {
    left: 30px;
  }

  .expert-box.circle-box--10 {
    right: 50px;
    bottom: 40px;
  }

  .link-box.link-box--sign-up.link-box--green.center {
    width: 100%;
    max-width: 382px;
  }

  .expert-desc.signup {
    margin-left: 0px;
  }

  .expert-desc.signup._3 {
    margin-left: 0px;
  }

  .expert-desc.signup._1 {
    margin-top: 60px;
  }

  .expert-desc._2 {
    margin-top: 150px;
  }

  .form__row.payment {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .form.signup {
    margin-top: 24px;
  }

  .c-form__btn {
    display: block;
    margin-top: 24px;
  }

  .c-btn {
    display: block;
    margin-top: 24px;
  }

  .c-btn.btn--red {
    margin-top: 0px;
  }

  .c-form__h5 {
    margin-bottom: 15px;
  }

  .form__field-wrap.form__field-wrap--right {
    margin-left: 0px;
  }

  .form__field-wrap.form__field-wrap--left {
    margin-right: 20px;
    margin-left: 0px;
  }

  .c-btn-line-wrap {
    width: 100%;
  }

  .tab-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .tabs__menu {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .tabs__link {
    padding-right: 12px;
    padding-left: 12px;
  }

  .tabs__link.w--current {
    padding-right: 12px;
    padding-left: 12px;
    font-size: 13px;
  }

  .form__img.form__img--position {
    margin-right: 8px;
  }

  .breadcrumbs-wrap.breadcrumbs-wrap--stretch {
    left: 15px;
    display: block;
    width: 100%;
  }

  .breadcrumbs-wrap {
    padding-right: 15px;
  }

  .breadcrumbs-wrap__bg-right {
    display: none;
  }

  .breadcrumb-link {
    /* -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; */
    font-size: 13px;
  }

  .breadcrumb-link.breadcrumb-link--small {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 13px;
  }

  .breadcrumps-p {
    font-size: 10px;
  }

  .tabs__link-title {
    font-size: 13px;
  }

  .experts-circle-box {
    width: 60px;
    height: 60px;
    margin-right: 32px;
  }

  .help-title {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .faq-down-arrow {
    margin-left: 20px;
  }

  .help-text {
    font-size: 16px;
  }

  .help-h4 {
    font-size: 16px;
  }

  .expert-info-wrap {
    left: 20%;
    top: auto;
    right: auto;
    bottom: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    /* width: 80px;
    height: 70px; */
  }

  .expert-info-wrap--home {
    left: 0;
  }
  .services-wrap .expert-info-wrap .expert-desc {
    /* margin-top: 80px; */
  }

  .expert-info-wrap.expert-info-wrap--2.signup {
    right: 0px;
  }

  .expert-info-wrap.expert-info-wrap--3.signup {
    right: 60px;
    bottom: 0px;
  }

  .expert-info-wrap.expert-info-wrap--4.signup {
    top: 40px;
  }

  .expert-info-wrap.expert-info-wrap--6 {
    left: auto;
    top: auto;
    right: 18%;
    bottom: 20px;
  }

  .expert-info-wrap.expert-info-wrap--7 {
    left: auto;
    top: 10px;
    right: 10%;
    bottom: auto;
  }

  .expert-info-wrap.expert-info-wrap--8 {
    left: -2%;
    top: 35%;
    right: auto;
    bottom: auto;
  }

  .expert-info-wrap.expert-info-wrap--8.signup {
    left: 50px;
    top: 0%;
    right: auto;
    bottom: auto;
  }

  .expert-info-wrap.expert-info-wrap--9 {
    left: auto;
    top: 60px;
    right: 40%;
    bottom: auto;
  }

  .expert-info-wrap.expert-info-wrap--9.signup {
    left: 50px;
    top: auto;
    right: auto;
    bottom: 0%;
    z-index: 0;
  }

  .expert-info-wrap.expert-info-wrap--10 {
    left: 18%;
  }

  .expert-info-wrap.expert-info-wrap--10.signup {
    top: 30px;
  }

  .expert-info-wrap.expert-info-wrap--5.signup {
    left: auto;
    top: 0px;
    right: 150px;
    bottom: auto;
  }

  .back-link.back-link--arrow {
    left: 0px;
  }

  .request-wrap {
    margin-top: 48px;
  }

  .payment-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .date-box {
    width: 96px;
    height: 79px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .date-title {
    font-size: 3.5vw;
  }

  .meet-desc {
    margin-left: 24px;
  }

  .meet-h4 {
    font-size: 16px;
  }

  .subtitle-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .brand-logo {
    margin-bottom: 26px;
  }

  .about__h3 {
    margin-top: 32px;
  }

  .form__text {
    font-size: 13px;
  }

  .lottie {
    width: 100%;
    min-height: 250px;
  }

  .formules-wrap--home {
    padding-top: 40px;
  }
}

#w-node-80b5f0a915e3-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-cf4fc84944b9-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-cd05a88335b8-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8bceb5c5bc1c-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-61b67fcbaabd-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d830aae410d2-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8b728a7706c5-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8b728a7706d5-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8b728a7706e5-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8b728a7706f5-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8b728a770705-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8b728a770715-e82044ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-50f6dd8551e0-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-80b5f0a915e3-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-cd05a88335b8-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-8bceb5c5bc1c-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f420f2b26690-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d3ca92b1c51a-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-7d3498cb1489-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-9b0f0d8c9df1-1acbee63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

@media screen and (max-width: 991px) {
  #w-node-80b5f0a915e3-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cf4fc84944b9-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cd05a88335b8-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8bceb5c5bc1c-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-61b67fcbaabd-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d830aae410d2-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8b728a7706c5-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8b728a7706d5-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8b728a7706e5-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8b728a7706f5-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8b728a770705-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8b728a770715-e82044ca {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-50f6dd8551e0-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-80b5f0a915e3-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cd05a88335b8-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-8bceb5c5bc1c-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f420f2b26690-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d3ca92b1c51a-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-7d3498cb1489-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-9b0f0d8c9df1-1acbee63 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-80b5f0a915e3-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-cf4fc84944b9-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-cd05a88335b8-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8bceb5c5bc1c-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-61b67fcbaabd-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-d830aae410d2-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8b728a7706c5-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8b728a7706d5-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8b728a7706e5-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8b728a7706f5-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8b728a770705-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8b728a770715-e82044ca {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-50f6dd8551e0-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-80b5f0a915e3-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-cd05a88335b8-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-8bceb5c5bc1c-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-f420f2b26690-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-d3ca92b1c51a-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-7d3498cb1489-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-9b0f0d8c9df1-1acbee63 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
}