.container {

  &--nav {
    
    @media (max-width: 991px) {
      padding-left: 16px;

      .brand.w--current {
        left: 16px;
      }

      .w-nav-button {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }

  &--hero {

    &.payment {
      min-height: auto;

      .checkout-form {
        padding-top: 50px;
      }
    }
  }

  &.container--with-breadcrumbs {
    min-height: auto;

    .profile-loader-wrapper {
      padding: 50px 0;
    }
  }
}