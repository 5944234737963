.landing {
  
  .text-section {
    max-width: 833px;
    margin: 0 auto;
    padding: 103px 18px 121px;

    h3 {

      span {
        display: block;
      }
    }

    @include sm {
      padding: 60px 18px;
    }

    &__head {
      margin-bottom: 53px;
      text-align: center;

      @include sm {
        margin-bottom: 25px;
      }
      @include xs {
        h3 {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }

    &__body {
      margin: 0 auto 61px;
      max-width: 484px;

      @include sm {
        margin-bottom: 31px;
        max-width: 280px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.357;
        color: #000;
        opacity: 0.6;

        span {
          display: block;
        }

        @include xs {
          font-size: 10px;
          line-height: 19px;
        }
      }
    }

    &__foot {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {

        &:first-child {
          margin-right: 39px;
        }

        @include sm {
          &:first-child {
            margin-right: 0;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }

    &--2 {
      padding-bottom: 20px;

      @include xs {
        padding: 27px 29px 0;

        .text-section__head,
        .text-section__body p {
          text-align: left;
        }
        .text-section__body {
          max-width: 100%;
        }
        .text-section__head h3 {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
  }
}
