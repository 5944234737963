#email-form {

  label {
    margin-bottom: 10px;
  }
}

.title {
  width: 100%;
}

.subtitle {
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-top: 35px;
}

.appointment-summary {
  margin-top: 20px;
  h5 {
    font-size: 1.1rem;
    margin-top: 10px;

    .field-title {
      font-weight: bold;
    }
  }
}

.button {
  padding: 0 35px 0 35px !important;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.contact-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .w-checkbox.checkbox-field {
    margin-top: 14px;
    margin-bottom: 0px;
    margin-left: 20px;
    span {
      font-size: 0.85rem;
    }
  }
}
