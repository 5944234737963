.landing {

  .intro-two {
    background-image: url('../../_assets/intro_2_bg.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    margin-top: -50px;

    @include xxs {
      background-position: center center;
    }

    @media (min-width: 1440px) {
      width: calc(100vw - 17px);
      margin-left: -108px;
    }

    &__inner {
      max-width: 1440px;
      padding: 185px 100px 51px;
      position: relative;

      @include md {
        padding: 150px 60px 40px;
      }

      @include sm {
        padding: 100px 30px 30px;
      }

      @include xxs {
        padding: 70px 30px 30px;
      }

      &_lottie {
        position: absolute;
        top: -7px;
        left: 100px;
        max-width: 520px;

        @include md {
          left: 60px;
          max-width: 300px;
        }
        @include sm {
          left: 30px;
          max-width: 240px;
        }
      }

      .button {

        @include xxs {
          font-size: 12px;
          padding: 0 12px;
        }
      }
    }

    h2 {
      margin-bottom: 30px;
      max-width: 520px;

      @include xxs {
        margin-bottom: 20px;
      }
    }

    &__textbox {
      max-width: 537px;
      padding: 20px 36px 28px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      margin-bottom: 40px;
      position: relative;

      @include md {
        max-width: 380px;
      }
      @include sm {
        max-width: 300px;
        padding: 5px 10px;
        border-radius: 10px;
      }
      @include xxs {
        max-width: 175px;
        margin-bottom: 20px;
      }
      
      &_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 25px 0 25px 25px;
        overflow: hidden;

        @include sm {
          border-radius: 10px 0 10px 10px;
        }

        div {
          background-color: #fff;
          filter: blur(6px);
          position: absolute;
          top: -10%;
          left: -10%;
          width: 120%;
          height: 120%;
          border-radius: 25px 0 25px 25px;
          opacity: 0.75;

          @include sm {
            border-radius: 10px 0 10px 10px;
          }
        }
      }

      &_right {
        width: 90px;
        height: 45px;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: -90px;

        .inner {
          width: 80px;
          height: 89px;
          position: absolute;
          top: 0;
          left: -30px;
          opacity: 0.75;
          transform: rotate(49deg);
          transform-origin: right top;
          border-radius: 0 12px 0 0;
          overflow: hidden;

          @include md {
            width: 60px;
            height: 70px;
          }
          @include sm {
            width: 50px;
            height: 58px;
          }

          div {
            width: 110px;
            height: 110px;
            position: absolute;
            top: -15px;
            left: -15px;
            background-color: #fff;
            filter: blur(6px);
            border-radius: 12px;
          }
        }
      }

      p {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 33px;
        line-height: 1.228;
        font-weight: 400;
        color: #000;
        opacity: 0.55;
        text-align: left;

        @include md {
          font-size: 24px;
        }
        @include sm {
          font-size: 20px;
        }
        @include xxs {
          font-size: 12px;
        }
      }
    }
  }
}
