.voucher-info-box {
  margin-top: 16px;
  font-size: 13px;
  line-height: 1.3;
  color: #9bb1c8;
  border: 1px solid rgba(39, 7, 223, 0.3);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: -38px;
}

.services-wrap {

  &--space-bottom {
    margin-bottom: 60px;
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
}