.landing {

  .sec {
    padding: 0;
  }

  .intro {
    max-width: 1271px;
    margin: 0 auto;
    padding: 140px 15px 84px;
    position: relative;

    @include md {
      padding-top: 100px;
    }
    @include xs {
      padding-top: 60px;
      overflow: hidden;
    }
    @include xxs {
      padding: 80px 29px 20px;
      margin-top: -50px;
    }

    &__left {
      max-width: 633px;
      width: 100%;
      position: relative;
      z-index: 1;

      h1 {
        margin-bottom: 90px;

        @include md {
          margin-bottom: 60px;
        }
        @include sm {
          margin-bottom: 50px;
        }
        @include xs {
          margin-bottom: 40px;
        }
        @include xxs {
          margin-bottom: 30px;
        }
  
        span {
          display: block;
        }
      }

      .button {

        @include xs {
          height: 20px;
          padding: 0 12px;
          font-size: 12px;

          &--white {
            display: none;
          }
        }
      }
    }

    &__right {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 50%;

      @include md {
        max-width: 60%;
        bottom: -30px;
      }
      @include xs {
        max-width: 70%;
        bottom: -10px;
        right: -50px;
      }

      .lottie-wrapper {
        overflow: hidden;

        .lottie-container {
          margin: -10px;
        }
      }
    }

  }
}